<div class="outer">
  <header class="d-flex">
    <h2>
      BANK INFORMATION
    </h2>
    <!-- <button mat-icon-button class="ml-auto" (click)="edit(editFrom)">
        <img src="assets/icons/Blue edit.png" alt="">
    </button> -->
  </header>
  <mat-card>

    <div class="d-flex my-3" *ngIf="bankAccounts?.isLoading">
      <mat-progress-spinner class="m-auto" [strokeWidth]="2" [diameter]="40" [mode]="'indeterminate'">
      </mat-progress-spinner>
    </div>

    <div class="text-center" *ngIf="!bankAccounts?.isLoading && !bankAccounts?.items?.length">

      <img class="mb-3 w-50" src="https://img.icons8.com/bubbles/200/000000/image-file.png" />
      <h6>No bank account available</h6>
      <br>
    </div>

    <mat-card class="bank-card" *ngFor="let item of bankAccounts?.items">
      <h5 class="mb-1">
        <strong>{{item?.accountHolderName | titlecase}}</strong>
        <h6 class="ml-3 d-inline mb-0">{{item?.sortCode | titlecase}}</h6>
        <button mat-icon-button class="float-right" [class.text-muted]="!item.isLoading" (click)="delete(item)" [disabled]="item?.isLoading" >
          <mat-icon>delete</mat-icon>
        </button>
      </h5>
      <h6 class="mb-1">
        <!-- {{item?.accountHolderName | titlecase}}&nbsp;&nbsp; -->
        {{item?.accountNumber | squire}}</h6>
    </mat-card>

    <button mat-stroked-button class="w-100" color="primary" (click)="addBankAccount()">
      <mat-icon>add</mat-icon>
      Add Bank Account
    </button>

    <!-- <div>
      <span>Account Holder's Name :</span>&nbsp;<strong>N/a</strong>
    </div>
    <div>
      <span>Account Number :</span>&nbsp;<strong>N/a</strong>
    </div>
    <div>
      <span>Bank Name :</span>&nbsp;<strong>N/a</strong>
    </div>
    <div>
      <span>IFSC Code :</span>&nbsp;<strong>N/a</strong>
    </div> -->
  </mat-card>
</div>

<!-- <ng-template #editFrom>

  <form [formGroup]="form">
    <div class="py-4 px-3">
      <h3>ADD BANK DETAILS</h3>
      <button mat-icon-button class="close" (click)="dialogRef.close()">
        <mat-icon>clear</mat-icon>
      </button>
      <div class="row">
        <div class="col-6">
          <div class="form-group">
            <label for="name">Account Holder's Name</label>
            <input id="name" type="text" class="form-control" formControlName="accountHolderName">
          </div>
        </div>

        <div class="col-6">
          <div class="form-group">
            <label for="number">Account Number</label>
            <input id="number" type="text" class="form-control" formControlName="accountNumber">
          </div>
        </div>

        <div class="col-6">
          <div class="form-group">
            <label for="bank">Bank Name</label>
            <input id="bank" type="text" class="form-control" formControlName="bankName">
          </div>
        </div>

        <div class="col-6">
          <div class="form-group">
            <label for="code">IFSC Code</label>
            <input id="code" type="text" class="form-control" formControlName="ifscCode">
          </div>
        </div>
      </div>
      <footer class="text-center">
        <button ButtonUi color="c1" size="lg">Add</button>
      </footer>
    </div>
  </form>

</ng-template> -->
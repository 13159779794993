import { Injectable } from "@angular/core";
import { IApi } from '../http-handler/common/contracts/api';
import { HttpClient } from '@angular/common/http';
import { GenericApi } from '../http-handler/common/generic-api';
import { InviteEmail } from '../models/invite-email.model';

@Injectable({
    providedIn:'root'
})

export class InviteEmailService{
    invitedEmails:IApi<InviteEmail>;

    constructor(http:HttpClient){
        this.invitedEmails = new GenericApi<InviteEmail>('invitedEmails',http);
    }
}
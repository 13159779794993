<mat-drawer-container>
  <mat-drawer opened mode="side">
    <app-side-nav></app-side-nav>
  </mat-drawer>
  <mat-drawer-content>
    <app-header-nav></app-header-nav>
    <div class="content">
      <router-outlet></router-outlet>
    </div>
    <footer class="d-flex mb-1 bottom-links">
      <div class="mx-auto d-flex w-25">
        <a target="_blank" routerLink="/privacy-policy">Privacy policy</a>
        <a target="_blank" routerLink="/terms-conditions">Terms of use</a>
        <a target="_blank" routerLink="/about-us">About us</a>
      </div>
    </footer>
  </mat-drawer-content>
</mat-drawer-container>

<!-- <div class="floating-action" [class.opened]="actionIsOpened">
  <div class="actions">
    <div>
      <button
        [tooltip]="'Contribute to a CollectaGift Pot'"
        mat-icon-button
        (click)="contribute()"
      >
        <img src="assets/icons/Contribute In Campaign.png" alt="" />
      </button>
    </div>
    <div>
      <button
        [tooltip]="'Create a new CollectaGift Pot'"
        mat-icon-button
        routerLink="/pages/create-gift"
      >
        <img src="assets/icons/Create.png" alt="" />
      </button>
    </div>
  </div>
  <button
    (click)="actionIsOpened = !actionIsOpened"
    mat-icon-button
    class="add"
  >
    <mat-icon>add</mat-icon>
  </button>
</div> -->
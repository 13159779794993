import { AfterViewInit, Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-Terms-Conditions',
  templateUrl: './Terms-Conditions.component.html',
  styleUrls: ['./Terms-Conditions.component.scss']
})
export class TermsConditionsComponent implements AfterViewInit {

  constructor() { }

  ngAfterViewInit() {
    window.scrollTo(0, 0)
  }

}

import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-notLoginPopup',
  templateUrl: './notLoginPopup.component.html',
  styleUrls: ['./notLoginPopup.component.scss']
})
export class NotLoginPopupComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<NotLoginPopupComponent>, private router: Router) { }

  ngOnInit() { }

  navigateToLogin() {
    this.dialogRef.close(true);
    this.router.navigate(['/auth/sign-in'], {
      queryParams: {
        redirectTo: 'create-gift'
      }
    })
  }

}

import { Component, OnInit } from '@angular/core';
import { Page } from 'src/app/http-handler/common/contracts/page';
import { Campaign } from 'src/app/models/campaign.model';
import { AppService } from 'src/app/services/app.service';
import { CampaignService } from 'src/app/services/campaign.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-campaigns',
  templateUrl: './campaigns.component.html',
  styleUrls: ['./campaigns.component.scss']
})
export class CampaignsComponent implements OnInit {

  campaigns: Page<Campaign>;

  constructor(
    private _appService: AppService,
    private _campaignsService: CampaignService,
    private _router: Router
  ) {

    this.campaigns = new Page({
      api: _campaignsService.campaigns,
      serverPaging: false,
      filters: [{
        field: 'createdAt',
        value: null
      }, {
        field: 'collectionDeadline',
        value: null
      }, {
        field: 'status',
        value: '',
      }, {
        field: 'sortBy',
        value: '',
      }]
    });

    this.fetch(true);

  }

  ngOnInit() {
  }

  fetch(loading?: boolean) {
    if (loading) this._appService.loadingShow();
    this.campaigns.fetch().then(d => {
      this._appService.loadingHide();
    }).catch(err => {
      this._appService.loadingHide();
      this._appService.snackBarOpen(err);
    })
  }

}

import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AppService } from 'src/app/services/app.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BankAccountsService } from 'src/app/services/bank-accounts.service';
import { Page } from 'src/app/http-handler/common/contracts/page';
import { BankAccount } from 'src/app/models/bank-account.model';
import { AddBankAccountComponent } from 'src/app/modules/shareModule/dialogs/add-bank-account/add-bank-account.component';

@Component({
  selector: 'app-bank-acount',
  templateUrl: './bank-acount.component.html',
  styleUrls: ['./bank-acount.component.scss']
})
export class BankAcountComponent implements OnInit {

  // form:FormGroup = new FormGroup({
  //   accountHolderName:new FormControl('',[Validators.required]),
  //   accountNumber:new FormControl('',[Validators.required]),
  //   ifscCode:new FormControl('',[Validators.required]),
  //   bankName:new FormControl('',[Validators.required]),
  // });

  bankAccounts:Page<BankAccount>;


  constructor(
    private _appService:AppService,
    private _dialog:MatDialog,
    private _bankAccountService:BankAccountsService
  ) {

    this.bankAccounts = new Page({
      api:_bankAccountService.bankAccounts,
      properties:new BankAccount(),
      serverPaging:false
    });

    this.fetch();

   }

  ngOnInit() {
  }

  fetch(){
    this.bankAccounts.fetch().then(d => {

    }).catch(err => {
      this._appService.snackBarOpen(err);
    })
  }

  addBankAccount(){
    let bank = this._appService.matDialog.open(AddBankAccountComponent,{
      maxWidth:'100%',
      maxHeight:'100%'
    });
    bank.afterClosed().subscribe(d => {
      if(d){
        this.bankAccounts.add(d);
      }
    })
  }

  delete(b:BankAccount){
    if(!confirm('Are your sure want to delete your bank account detail?')){
      return;
    }
    b.isLoading = true;
    this._bankAccountService.bankAccounts.remove(b.id).then(d => {
      this.bankAccounts.remove(b);
      this._appService.snackBarOpen('Bank account successfully deleted.');
    }).catch(err => {
      b.isLoading = false;
      this._appService.snackBarOpen(err);
    })
  }

  // edit(temp){
  //  let bank = this._dialog.open(temp,{
  //     disableClose:true,
  //     minWidth:'500px',
  //     maxWidth:'100%',
  //     maxHeight:'100%'
  //   })
  // }

}

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Campaign } from 'src/app/models/campaign.model';
import { ProdigiProduct } from 'src/app/models/prodigiProduct.model';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'app-collage-maker-dialog',
  templateUrl: './collage-maker-dialog.component.html',
  styleUrls: ['./collage-maker-dialog.component.scss'],
})
export class CollageMakerDialogComponent implements OnInit {
  isLoading: boolean = false;
  file: File;
  container = {
    width: 318,
    height: 450,
    ratio: 0.74,
  };

  constructor(
    public dialogRef: MatDialogRef<CollageMakerDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { campaign: Campaign; productDetail?: ProdigiProduct },
    private _appService: AppService
  ) {
    this.collageSettings();
  }

  ngOnInit() {}

  collageSettings() {
    let pageH = this.container.height;
    let pageW = this.container.width;
    let ratio = pageW / pageH;
    if (this.data?.productDetail) {
      pageH = this.data?.productDetail?.height;
      pageW = this.data?.productDetail?.width;
      ratio = this.data?.productDetail?.ratio;
    }
    this.container.ratio = ratio;
    const dialogH = document.body.offsetHeight - 40;
    var callageH = dialogH - 100;
    var callageW = ratio * callageH;
    this.container.height = callageH;
    this.container.width = callageW;
  }

  onFailed() {
    this._appService.snackBarOpen('Some thing wrong please try again.');
  }

  onGenerateImage({ file }) {
    this.file = file;
    this.dialogRef.close(file);
    document.getElementById('closeBtn')?.click();
  }
}

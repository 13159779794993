import { Component, OnInit, Inject } from '@angular/core';
import { Page } from 'src/app/http-handler/common/contracts/page';
import { Country } from 'src/app/models/country.model';
import { AppService } from 'src/app/services/app.service';
import { CountriesService } from 'src/app/services/countries.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { BankAccountsService } from 'src/app/services/bank-accounts.service';
import { CardService } from 'src/app/services/card.service';
import { ValidationService } from '../../controls-msg/validation.service';

@Component({
  selector: 'app-add-bank-account',
  templateUrl: './add-bank-account.component.html',
  styleUrls: ['./add-bank-account.component.scss']
})
export class AddBankAccountComponent implements OnInit {

  countries: Page<Country>;

  form: FormGroup = new FormGroup({
    selectedCountry: new FormControl(''),
    country: new FormGroup({
      id: new FormControl('', [Validators.required]),
      countryName: new FormControl('', [Validators.required]),
      countryCode: new FormControl('', [Validators.required]),
      currencyCode: new FormControl('', [Validators.required]),
      columnName:new FormControl('', [Validators.required]),
    }),
    bankAccountDetail: new FormGroup({
      accountHolderName: new FormControl('', [Validators.required,ValidationService.spaceValidator]),
      accountNumber: new FormControl('', [Validators.required,ValidationService.spaceValidator,Validators.pattern(/^\d{8}$/i)]),
      country: new FormControl('', [Validators.required]),
      currency: new FormControl('', [Validators.required]),
      sortCode:new FormControl('', [Validators.required,ValidationService.spaceValidator,Validators.minLength(6)]),
      // address:new FormControl(this._appService.user.address || '', [Validators.required]),
      // city:new FormControl(this._appService.user.city || '', [Validators.required]),
      // postalCode:new FormControl(this._appService.user.postalCode || '', [Validators.required]),
    })
  })

  isLoading: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<AddBankAccountComponent>,
    @Inject(MAT_DIALOG_DATA) data: any,
    public _appService: AppService,
    private _countriesService: CountriesService,
    private _bankAccountsService: BankAccountsService,
    public cardService:CardService
  ) {

    this.form.get('country').valueChanges.subscribe(d => {
      if (d && d.countryCode) {
        this.form.get(['bankAccountDetail', 'country']).setValue(d.countryCode);
      }
      if (d && d.currencyCode) {
        this.form.get(['bankAccountDetail', 'currency']).setValue(d.currencyCode);
      }
    })

    dialogRef.disableClose = true;
    dialogRef.addPanelClass('add-bank-account');

    this.countries = new Page({
      api: _countriesService.stripeCountryLists,
      properties: new Country(),
      serverPaging: false
    });

    this.countries.fetch().then(d => {
      let item = d.items.find(i => i.countryName.toUpperCase().includes("UNITED STATES"));
      if(item) {
        this.form.get('selectedCountry').setValue(item);
        this.form.get('country').setValue(item);
      };
    });

  }

  ngOnInit() {
  }

  create() {
    this.isLoading = true;
    this._bankAccountsService.bankAccounts.create({ ...this.form.get('bankAccountDetail').value }).then(d => {
      this.dialogRef.close(d);
      this._appService.snackBarOpen('Bank Account successfully added.');
    }).catch(err => {
      this.isLoading = false;
      this._appService.snackBarOpen(err);
    })
  }

  get routingNumberLabel(): string {
    return this.form.get(['country', 'columnName']).value || '';
  }

}

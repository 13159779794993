import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IApi } from '../http-handler/common/contracts/api';
import { GenericApi } from '../http-handler/common/generic-api';
import { Prodigi, ProdigiProduct, ProdigiQuote } from '../models/prodigiProduct.model';

@Injectable({
  providedIn: 'root'
})
export class ProdigiService {

  prodigi: IApi<ProdigiProduct>;
  prodigiDetails: IApi<Prodigi>;

  constructor(
    http: HttpClient,
  ) {
    this.prodigi = new GenericApi<ProdigiProduct>('campaigns/prodigi', http);
    this.prodigiDetails = new GenericApi<ProdigiProduct>('prodigiDetails', http);

  }

  allProducts() {
    return this.prodigi.simpleGet({ path: 'all-products' }).then(d => {
      const items: ProdigiProduct[] = d?.products;
      return items?.map(i => {
        const height = i?.printAreaSizes?.default?.verticalResolution;
        const width = i?.printAreaSizes?.default?.horizontalResolution;
        const ratio = width / height
        return { ...i, ratio, height, width };
      });
    }).catch(err => {
      console.log(err);
      return [];
    })
  }

  productDetail(productSku) {
    return this.prodigi.create({ productSku }, 'product-details').then(d => {
      const i = d.variants[0];
      const height = i?.printAreaSizes?.default?.verticalResolution;
      const width = i?.printAreaSizes?.default?.horizontalResolution / 4;
      const ratio = width / height
      return { ...d, height, width, ratio }
    }).catch(err => {
      console.log(err);
      return null;
    })
  }

  getQuote(productSku: string): Promise<ProdigiQuote> {
    return this.prodigi.create({ productSku }, 'get-quote').then(d => {
      const quote: ProdigiQuote = d[0];
      quote.cardPrice = parseFloat(quote.costSummary?.items?.amount);
      quote.shippingPrice = parseFloat(quote?.costSummary?.shipping?.amount);
      quote.totalPrice = quote.cardPrice + quote.shippingPrice;
      quote.currency = quote.costSummary?.shipping?.currency
      return { ...quote };
    }).catch(err => {
      console.log(err);
      return null;
    })
  }

  createOrder(body: Prodigi) {
    const { address_line1, address_line2, stateOrCounty, townOrCity, postalOrZipCode, countryCode, productSku, ...other } = body;
    const newBody: ProdigiProduct = { ...other };
    newBody.address = { line1: address_line1, line2: address_line2, stateOrCounty, townOrCity, postalOrZipCode, countryCode };
    newBody.sku = productSku;
    return this.prodigi.create(newBody, 'create-order').then(d => {
      return d
    }).catch(err => {
      console.log(err);
      return null;
    })
  }

  saveProdigiDetail(body: Prodigi): Promise<Prodigi> {
    return this.prodigiDetails.create(body).then(d => {
      return d
    }).catch(err => {
      console.log(err);
      return null;
    })
  }

}

import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Campaign } from "src/app/models/campaign.model";
import {
  Prodigi,
  ProdigiProduct,
  ProdigiQuote,
} from "src/app/models/prodigiProduct.model";
import { ValidationService } from "src/app/modules/shareModule/controls-msg/validation.service";
import { AppService } from "src/app/services/app.service";
import { ProdigiService } from "src/app/services/prodigi.service";

@Component({
  selector: "app-prodigi-form",
  templateUrl: "./prodigi-form.component.html",
  styleUrls: ["./prodigi-form.component.scss"],
})
export class ProdigiFormComponent implements OnChanges {
  @Input() campaign: Campaign;
  @Input() productDetail: ProdigiProduct;
  country;
  countryError: string;

  form: FormGroup = new FormGroup({
    id: new FormControl(""),
    campaignId: new FormControl(""),
    name: new FormControl("", [
      ValidationService.spaceValidator,
      Validators.required,
      Validators.minLength(3),
    ]),
    address_line1: new FormControl("", [
      ValidationService.spaceValidator,
      Validators.required,
      Validators.minLength(3),
    ]),
    address_line2: new FormControl("", [
      ValidationService.spaceValidator,
      Validators.required,
      Validators.minLength(3),
    ]),
    postalOrZipCode: new FormControl("", [
      ValidationService.spaceValidator,
      Validators.required,
    ]),
    countryCode: new FormControl("GBP", [Validators.required]),
    townOrCity: new FormControl("", [
      ValidationService.spaceValidator,
      Validators.required,
      Validators.minLength(3),
    ]),
    stateOrCounty: new FormControl("UK", [
      ValidationService.spaceValidator,
      Validators.required,
      Validators.minLength(2),
    ]),
    productSku: new FormControl(""),
    cardCharges: new FormControl(""),
    shippingCharges: new FormControl(""),
    totalCharges: new FormControl(""),
    imgUrl: new FormControl(""),
  });

  constructor(
    private _appService: AppService,
    private _prodigiService: ProdigiService
  ) {
    this.form.get("countryCode").valueChanges.subscribe((d) => {
      if (!this.productDetail?.variants[0]?.shipsTo?.includes(d)) {
        this.countryError = "";
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["campaign"]) {
      this.form.get("campaignId").setValue(this.campaign.id);
      if (this.campaign?.isAmountProcessed) {
        this.form.disable();
      }
      if (this.campaign?.prodigiDetail) {
        this._appService.formGroupSetValue(
          this.form,
          this.campaign?.prodigiDetail
        );
        this.getQuote();
      }
    }
    if (changes["productDetail"]) {
      this.form.get("productSku").setValue(this.productDetail?.sku);
      this.getQuote();
    }
  }

  async save() {
    const data = await this._prodigiService.saveProdigiDetail(this.form.value);
    if (data?.id) {
      this.form.get("id").setValue(data?.id);
    }
  }

  async createOrder() {
    const body: Prodigi = this.form.value;
    await this._prodigiService.createOrder(this.form.value);
  }

  isGetingQuote: boolean = false;
  async getQuote() {
    if (this.isGetingQuote || this.form.invalid || !this.productDetail?.sku)
      return null;
    this.isGetingQuote = true;
    const qoute = await this._prodigiService.getQuote(this.productDetail?.sku);
    this.isGetingQuote = false;
    if (qoute) {
      this.form.get("cardCharges").setValue(qoute.cardPrice);
      this.form.get("shippingCharges").setValue(qoute.shippingPrice);
      this.form.get("totalCharges").setValue(qoute.totalPrice);
    } else {
      this.form.get("cardCharges").reset();
      this.form.get("shippingCharges").reset();
      this.form.get("totalCharges").reset();
    }
  }

  get priceSummary() {
    const { cardCharges, shippingCharges, totalCharges, countryCode } =
      this.form.value;
    if (cardCharges && shippingCharges && totalCharges) {
      return { cardCharges, shippingCharges, totalCharges, countryCode };
    } else {
      return null;
    }
  }

  get totalCardPrice() {
    return this.priceSummary?.totalCharges;
  }

  get invalid() {
    return !this.priceSummary?.totalCharges || this.form.invalid;
  }
}

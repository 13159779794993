export const environment = {
  production: false,
  env: 'dev',
  apiUrls: {
    api: 'https://mongopay.collectagift.com',
  },
  // stripePK:
  //   "pk_test_51GuKiXDFAod7Kw1JGhK1Pb3NsIRNgAIdl37l0vfHY5PesV7tfunN6FSygQT8K4cjGqP0jgcKCYIHEJQBWOwDQaTf00lJ4H49U6", // old,
  stripePK:
    'pk_test_51HpL7FEmO2b7uwYz5Axfk0O2yJQnwr9faCwH6BKeIe3G6I72AxXDi5t7WU95g42QtAeQHKK1ayQL9C8H1tRs1TSW00Vy4odoHl', // new
  stripePayoutLink:
    // ca_Hn0KIcUvhcsLHG7ZQb5tLfk8oDyVlG14
    'https://connect.stripe.com/oauth/authorize?response_type=code&client_id=ca_KOXeiV2keir8uUdBmn9xzNjaYu9lGHSk&scope=read_write',
  firebaseConfig: {
    // apiKey: 'AIzaSyArWbAx3U-j67LpQUckTR3B4l-JmKoJ928',
    // authDomain: 'collect-a-gift.firebaseapp.com',
    // databaseURL: 'https://collect-a-gift.firebaseio.com',
    // projectId: 'collect-a-gift',
    // storageBucket: 'collect-a-gift.appspot.com',
    // messagingSenderId: '130247618812',
    // appId: '1:130247618812:web:65934ba2d99d5c66352779',
    // measurementId: 'G-HCKW72MHQX',
    apiKey: 'AIzaSyDpIXUPNhMdkqIMqiHPmYCIKJLDkuKZlpk',
    authDomain: 'collectagift-3b895.firebaseapp.com',
    databaseURL: 'https://collectagift-3b895-default-rtdb.firebaseio.com',
    projectId: 'collectagift-3b895',
    storageBucket: 'collectagift-3b895.appspot.com',
    messagingSenderId: '329689445810',
    appId: '1:329689445810:web:160a8ae7ea6cec93bca0ab',
    measurementId: 'G-PGY8DDR4CQ',
  },
};

// export const environment = {
//   production: true,
//   env: "prod",
//   apiUrls: {
//     api: "https://qa.collectagift.com",
//   },
//   // stripePK: 'pk_live_dBhgPoSdxnMC4JXZFnW1dc5U00BY1d8iVe', // old
//   stripePK:
//     "pk_live_51HpL7FEmO2b7uwYzsTcTfoNdiVZGuAyQ0mOm23IuJZW3v2moWc1ZwQeA7AUJKxOnEEHDufw5kJNFlhAA7WodarjD00yVda6GvH", // new
//   stripePayoutLink:
//     // ca_Hn0KIcUvhcsLHG7ZQb5tLfk8oDyVlG14
//     "https://connect.stripe.com/oauth/authorize?response_type=code&client_id=ca_KOXeiV2keir8uUdBmn9xzNjaYu9lGHSk&scope=read_write",
//   firebaseConfig: {
//     // apiKey: "AIzaSyArWbAx3U-j67LpQUckTR3B4l-JmKoJ928",
//     // authDomain: "collect-a-gift.firebaseapp.com",
//     // databaseURL: "https://collect-a-gift.firebaseio.com",
//     // projectId: "collect-a-gift",
//     // storageBucket: "collect-a-gift.appspot.com",
//     // messagingSenderId: "130247618812",
//     // appId: "1:130247618812:web:65934ba2d99d5c66352779",
//     // measurementId: "G-HCKW72MHQX",

//     apiKey: "AIzaSyDpIXUPNhMdkqIMqiHPmYCIKJLDkuKZlpk",
//     authDomain: "collectagift-3b895.firebaseapp.com",
//     databaseURL: "https://collectagift-3b895-default-rtdb.firebaseio.com",
//     projectId: "collectagift-3b895",
//     storageBucket: "collectagift-3b895.appspot.com",
//     messagingSenderId: "329689445810",
//     appId: "1:329689445810:web:160a8ae7ea6cec93bca0ab",
//     measurementId: "G-PGY8DDR4CQ",
//   },
// };

import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TremendousProduct } from 'src/app/models/tremendous.product.model';
import { CampaignService } from 'src/app/services/campaign.service';
@Component({
  selector: 'app-browse-available-gift-providers',
  templateUrl: './browse-available-gift-providers.component.html',
  styleUrls: ['./browse-available-gift-providers.component.scss'],
})

export class BrowseAvailableGiftProvidersComponent implements OnInit {

  tremendousProducts: { list: TremendousProduct[]; isLoading: boolean } = {
    list: [],
    isLoading: false,
  };
  searchVal: any;
  fullTremendousList: TremendousProduct[];
  constructor(
    public dialogRef: MatDialogRef<BrowseAvailableGiftProvidersComponent>,
    private _campaignService: CampaignService
  ) {
    dialogRef.addPanelClass('create-catalogue-product-and-proceed');
    this.fetchTremendousProducts();
  }

  ngOnInit() { }
  async fetchTremendousProducts() {
    this.tremendousProducts.isLoading = true;
    this.tremendousProducts.list =
      await this._campaignService.getTremendousProduct();
    this.fullTremendousList = this.tremendousProducts.list;
    this.tremendousProducts.isLoading = false;
  }

  async search() {
    this.tremendousProducts.isLoading = true;
    const searchVal = this.searchVal.toLowerCase();
    this.tremendousProducts.list = this.fullTremendousList
    if (!this.searchVal) {
      this.tremendousProducts.isLoading = false;
      return;
    }
    this.tremendousProducts.isLoading = true;
    this.tremendousProducts.list = this.tremendousProducts.list.filter(list => {
      const name = list.name.toLowerCase();
      return name.includes(searchVal)
    })
    this.tremendousProducts.isLoading = false;

  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'categoryColor'
})
export class CategoryColorPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    return this.color(value);
  }

  color(v: string) {
    switch (v) {
      case 'Birthday':
        return 'pink';
      case 'New Baby':
        return 'pink';
      case 'Thinking of You':
        return 'yellow';
      case 'Congratulations':
        return 'yellow';
      case 'New Job':
        return 'blue';
      case 'Engagement':
        return 'purple';
      case 'Get Well Soon':
        return 'blue';
      case 'With Sympathy':
        return 'light-purple';
      case 'Retirement':
        return 'light-purple';
      case 'Wedding':
        return 'purple';
      case 'Other':
        return 'yellow';
      default :
        return 'light-purple';
    }
  }

}

<div class="container-fluid">
  <div class="header mt-1">
    <div class="row">
    <div class="col-lg-4">
    <h3 class="m-0">{{ "My Contributions" | uppercase }}</h3>
  </div>
    <div class="d-flex justify-content-end col-lg-8">
      <div class="row">
      <div class="pl-3 col-lg-6">
        <img class="mr-2" src="assets/icons/Filter.png" alt="" />
        <mat-form-field>
          <input
            id="dateFilter"
            readonly
            class="cursor-pointer"
            matInput
            (dateChange)="fetch(true)"
            [matDatepicker]="date"
            (click)="date.open()"
            [(ngModel)]="contribution.filters.properties['createdAt'].value"
            type="text"
            placeholder="Filter by creation Date"
          />
          <mat-datepicker #date></mat-datepicker>
          <button
            mat-icon-button
            *ngIf="contribution.filters.properties['createdAt'].value"
            matSuffix
            type="button"
            (click)="
              contribution.filters.properties['createdAt'].value = '';
              fetch(true)
            "
          >
            <mat-icon>clear</mat-icon>
          </button>
          <mat-icon
            *ngIf="!contribution.filters.properties['createdAt'].value"
            matSuffix
            (click)="date.open()"
          >
            keyboard_arrow_down</mat-icon
          >
        </mat-form-field>
      </div>
      <div class="pl-3  col-lg-6">
        <img class="mr-2" src="assets/icons/Filter.png" alt="" />
        <mat-form-field>
          <mat-label>Filter by status</mat-label>
          <mat-select
            #status="matSelect"
            (click)="status.open()"
            [(ngModel)]="contribution.filters.properties['paymentStatus'].value"
            (selectionChange)="fetch(true)"
          >
            <mat-option value="paid">Paid</mat-option>
            <mat-option value="pending">Pending</mat-option>
          </mat-select>
          <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
        </mat-form-field>
      </div>
      </div>
    </div>
  </div>
</div>

  <mat-card>
    <table class="table table-responsive dekstop">
      <thead>
        <tr>
          <!-- <th>#</th> -->
          <th>Collection Pot</th>
          <th>Purpose of gift</th>
          <th>Created By</th>
          <th style="width: 180px">Your Contribution</th>
          <th style="width: 100px">Status</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of contribution?.items; let i = index">
          <!-- <td>{{(contribution.pageNo-1)*contribution.pageSize+1+i}}</td> -->
          <td>{{ (item?.campaign?.receipientName | titlecase) || "N/a" }}</td>
          <td>
            {{
              (item?.campaign?.purposeOfGift || item?.campaign?.category?.name
                | titlecase) || "N/a"
            }}
          </td>
          <td>
            <div class="d-flex align-items-center">
              <img
                class="rounded-circle mr-2"
                height="35px"
                [src]="item?.campaign?.user?.imgUrl"
                onerror="this.src = 'assets/images/team-man-placeholder.jpg'"
                alt=""
              />
              <div class="data-content">
                <div class="title text-dark font-weight-bold">
                  {{ (item?.campaign?.user?.name | titlecase) || "N/a" }}
                </div>
                <div class="title">
                  Created on :
                  <span class="text-dark font-weight-bold">{{
                    item?.campaign?.createdAt | date: "dd-MM-yyyy"
                  }}</span>
                </div>
              </div>
            </div>
          </td>
          <td>
            {{
              item?.amount - item?.stripeCharges || 0
                | currency: item?.campaign?.campaignCurrency || "GBP"
            }}
          </td>
          <td>
            <span class="status" [ngClass]="item.paymentStatus">{{
              (item?.paymentStatus | titlecase) || "N/a"
            }}</span>
          </td>
        </tr>
      </tbody>
    </table>
    <div *ngIf="!contribution.isLoading && !contribution.items.length">
      <div class="m-auto text-center">
        <img src="https://img.icons8.com/bubbles/200/000000/image-file.png" />
        <h6>No contributions available</h6>
      </div>
    </div>
    <hr />
    <mat-paginator
      [pageSize]="contribution.pageSize"
      [pageSizeOptions]="[5, 10, 25, 50, 100]"
      [length]="contribution.totalRecords"
      [pageIndex]="contribution.pageNo - 1"
      (page)="
        contribution.pageNo = $event.pageIndex + 1;
        contribution.pageSize = $event.pageSize;
        fetch(true)
      "
    >
    </mat-paginator>
  </mat-card>
</div>

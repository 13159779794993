<div class="container-fluid px-4">

  <h3>PERSONAL INFORMATION</h3>

  <mat-card class="mb-3 main-crd">

    <div class="header">
      <div class="img">
        <input type="file" class="d-none" (click)="file.value = null;" #file (change)="changeImage($event)">
        <img [lazyLoad]="user.imgUrl" [defaultImage]="'assets/images/team-man-placeholder.jpg'" alt="">
        <mat-icon matRipple (click)="file.click();">edit</mat-icon>
      </div>

      <div class="content">
        <span class="text-muted">Name :</span> &nbsp;
        <span>{{(user?.firstName | titlecase) || 'N/a'}} {{user?.lastName}}</span>
        <br>
        <span class="text-muted">Email :</span>&nbsp; <span>{{user.email}}</span>
      </div>

    </div>

    <hr>

    <div class="detail">

      <div class="detail-card col-lg-2 col-12 crd-detil" routerLink="../campaigns">
        <img src="assets/images/Campaigns 22.png" alt="">
        <div class="content">
          <h5>{{user?.campaignsCount || 0}}</h5>
          <h5>Campaigns</h5>
        </div>
      </div>

      <div class="detail-card col-lg-2 col-12" routerLink="../contributions">
        <img src="assets/images/contribution 133.png" alt="">
        <div class="content">
          <h5>{{user?.contributionsCount || 0}}</h5>
          <h5>Contributions</h5>
        </div>
      </div>

    </div>

  </mat-card>

  <div class="row">

    <div class="config col-12 col-lg-5">
      <h3>
        SETTINGS
      </h3>
      <mat-card>
        <div class="item" mat-list-item matRipple (click)="changePassword()">
          Change Password
        </div>
        <div class="item" mat-list-item matRipple (click)="editProfile(editProfileTemp)">
          Edit Profile
        </div>
        <div class="item d-flex" mat-list-item matRipple>
          Log in Method:
          <div class="ml-auto">
            <img class="px-3" [src]="user?.loginType == 'facebook' ?  'assets/icons/FB (2).png' : 'assets/icons/FB.png'"
              alt="">
            <img class="px-3"
              [src]="user?.loginType == 'google' ? 'assets/icons/Google.png': 'assets/icons/Google 1.png'" alt="">
            <img class="px-3"
              [src]="user?.loginType == 'mobile' ? 'assets/icons/Email (2).png': 'assets/icons/Email.png'" alt="">

          </div>
        </div>
        <div class="item bg-mute text-dark" (click)="deactivateAccount()" mat-list-item matRipple>
          Deactivate Account
        </div>
      </mat-card>
    </div>
    <!-- <div class="col">
      <h3>
        KYC DETAIL
      </h3>
      <mat-card>

        <div class="row" *ngIf="user?.address">

          <div class="col-6">
            <strong>Address</strong> <br>
            <span>{{user?.address || 'N/a'}}</span>
          </div>

          <div class="col-6">
            <strong>State</strong> <br>
            <span>{{(user?.state | titlecase) || 'N/a'}}</span>
          </div>

          <div class="col-6">
            <strong>City</strong> <br>
            <span>{{(user?.city | titlecase) || "N/a"}}</span>
          </div>

          <div class="col-6">
            <strong>Postal code</strong> <br>
            <span>{{user?.postalCode || 'N/a'}}</span>
          </div>

          <div class="col-6">
            <strong>Phone</strong> <br>
            <span>{{user?.phone || 'N/a'}}</span>
          </div>


        </div>



        <div *ngIf="!user?.address">
          <div class="m-auto text-center">
            <img src="https://img.icons8.com/bubbles/200/000000/image-file.png" />
            <h6>No KYC Available </h6>
          </div>
          <button mat-stroked-button class="w-100" color="primary" (click)="addProofs()">
            <mat-icon>add</mat-icon>
            Complete Your KYC
          </button>
        </div>

      </mat-card>
    </div> -->
  </div>

</div>

<ng-template #editProfileTemp let-dialog>


  <form [formGroup]="editProfileForm">
    <header>
      <h4 class="m-0">
        Edit Profile
      </h4>
      <button type="button" class="ml-auto" mat-icon-button matDialogClose>
        <mat-icon>clear</mat-icon>
      </button>
    </header>

    <mat-progress-bar *ngIf="isLoading" [mode]="'indeterminate'"></mat-progress-bar>

    <br>
    <div class="px-3">
      <div>
        <mat-form-field [appearance]="'outline'">
          <mat-label>First Name</mat-label>
          <input type="text" matInput formControlName="firstName" maxlength="30" validatorController char="true">
        </mat-form-field>
      </div>

      <div>
        <mat-form-field [appearance]="'outline'">
          <mat-label>Last Name</mat-label>
          <input type="text" matInput formControlName="lastName" maxlength="30" validatorController char="true">
        </mat-form-field>
      </div>
    </div>

    <footer class="text-right px-3 pb-3">
      <!-- <button type="button" mat-button ButtonUi color="c2" [disabled]="isLoading" class="mr-3"
        matDialogClose>Cancel</button> -->
      <button type="submit" mat-button ButtonUi color="c1" matDialogClose (click)="updateEditProfile()"
        [disabled]="isLoading || editProfileForm.invalid">
        Update
      </button>
    </footer>
  </form>
</ng-template>

import { ViewportScroller } from "@angular/common";
import { AfterViewInit, Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { Category } from "src/app/models/category.model";
import { User } from "src/app/models/user.model";
import { ValidationService } from "src/app/modules/shareModule/controls-msg/validation.service";
import { StartWithContributeComponent } from "src/app/modules/shareModule/dialogs/start-with-contribute/start-with-contribute.component";
import { AppService } from "src/app/services/app.service";
import { CampaignService } from "src/app/services/campaign.service";
import { CategoriesService } from "src/app/services/categories.service";
import { ConfigurationService } from "src/app/services/configuration.service";
import { UserService } from "src/app/services/user.service";
declare var $: any;
@Component({
  selector: "app-about-us",
  templateUrl: "./about-us.component.html",
  styleUrls: ["./about-us.component.scss"],
})
export class AboutUsComponent implements OnInit, AfterViewInit {
  categories: Category[] = [];

  contactUsForm: FormGroup = new FormGroup({
    name: new FormControl("", [Validators.required]),
    email: new FormControl("", [
      Validators.required,
      ValidationService.emailValidator
    ]),
    message: new FormControl("", [Validators.required]),
  });

  public get aboutUsContent() {
    return this._configurationService.aboutUsContent;
  }

  public get homeHeaderImage() {
    return this._configurationService.homeHeaderImage;
  }

  constructor(
    private _categoriesService: CategoriesService,
    private _userService: UserService,
    private _appService: AppService,
    private _router: Router,
    private _dialog: MatDialog,
    private _activatedRoute: ActivatedRoute,
    private _campaignService: CampaignService,
    private _viewportScroller: ViewportScroller,
    private _configurationService: ConfigurationService
  ) {
    _configurationService.fetch();
    this._categoriesService.categories
      .search({ serverPaging: false } as any)
      .then((d) => {
        let colorCount = 0;
        d.items.forEach((c) => {
          if (colorCount > 5) {
            colorCount = 0;
          }
          colorCount++;
          c.color = colorCount;
        });
        this.categories = [...d.items];
      });

    this._activatedRoute.fragment.subscribe((d) => {
      _viewportScroller.scrollToAnchor(d);
    });
  }

  ngOnInit() {
    let queryParams = this._activatedRoute.snapshot.queryParams;
    let link: string = queryParams["redirectTo"];
    if (link && link.match(/\/pages\/contribution\/[a-zA-Z0-9]+/g)) {
      let linkAry = link.split("/");
      let campaignId = linkAry[linkAry.length - 1];
      this._appService.loadingShow();
      this._campaignService
        .getByInviteLink(campaignId)
        .then((d) => {
          console.log(d);
          
          this._appService.loadingHide();
          let expireOn: Date = new Date(d.collectionDeadline);
          console.log(expireOn);
          
          if (
            (expireOn.getTime() - new Date().getTime()) / (1000 * 3600 * 24) <
            0
          ) {
            this._appService.snackBarOpen("Link is expired");
          } else {
            this._appService.snackBarOpen(
              "To contribute, please login or register as a new user."
            );
            this._router.navigate(["auth/sign-up"], {
              queryParamsHandling: "preserve",
            });
            // this.startWithContribute();
          }
        })
        .catch((d) => {
          this._appService.loadingHide();
          this._appService.snackBarOpen("Invalid link");
        });
    }
  }

  get user(): User {
    return JSON.parse(localStorage.getItem("user"));
  }

  contactUs() {
    this._userService.users
      .create({ ...this.contactUsForm.value }, "contact/us")
      .then((d) => {
        // this._appService.snackBarOpen('err');
        this._appService.snackBarOpen(
          "Thank you for contacting CollectaGift. We will aim to come back to you within 24 hours."
        );
        this.contactUsForm.reset();
      })
      .catch((err) => {
        this._appService.snackBarOpen(err);
      });
  }

  navigate(link, defualt?: string) {
    if (localStorage.getItem("token")) {
      this._router.navigate([link]);
    } else {
      this._router.navigate([defualt ? defualt : "/auth/sign-in"], {
        queryParams: { redirectTo: link },
      });
    }
  }

  startWithContribute(link?: string) {
    this._dialog.open(StartWithContributeComponent, {
      disableClose: true,
      minHeight: "200px",
      maxHeight: "100%",
      minWidth: "600px",
      maxWidth: "100%",
    });
  }

  isOpened: boolean;

  ngAfterViewInit() {
    // $('#toggle').click(function () {
    //   $(this).toggleClass('active');
    //   $('#overlay').toggleClass('open');
    // });
    // $('.overlay-menu a').click(function () {
    //   $('.button_container').toggleClass('active');
    //   $('#overlay').toggleClass('open');
    //   return false;
    // });
  }
}

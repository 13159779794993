import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  TemplateRef,
  AfterViewInit,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppService } from 'src/app/services/app.service';
import { Page } from 'src/app/http-handler/common/contracts/page';
import { BankAccount } from 'src/app/models/bank-account.model';
import { BankAccountsService } from 'src/app/services/bank-accounts.service';
import { AddBankAccountComponent } from '../add-bank-account/add-bank-account.component';
import { CampaignService } from 'src/app/services/campaign.service';
import { Campaign } from 'src/app/models/campaign.model';
import { MatSnackBarRef } from '@angular/material/snack-bar';
import { log } from 'console';

@Component({
  selector: 'app-payout',
  templateUrl: './payout.component.html',
  styleUrls: ['./payout.component.scss'],
})
export class PayoutComponent implements OnInit, AfterViewInit {
  bankAccounts: Page<BankAccount>;
  isLoading: boolean;
  @ViewChild('customTostor') customTostor: TemplateRef<any>;
  customTostorRef: MatSnackBarRef<any>;

  constructor(
    public dialogRef: MatDialogRef<PayoutComponent>,
    private _appService: AppService,
    private _bankAccountService: BankAccountsService,
    private _campaignService: CampaignService,
    @Inject(MAT_DIALOG_DATA) public data: Campaign
  ) {
    console.log(data, 'payout data');
    dialogRef.addPanelClass('payout-dialog');
    dialogRef.disableClose = true;
    var {
      decidedGift,
      giftAmount,
      wantToDonate,
      charityAmount,
      userAmount,
      cardAmount,
      collectedAmount,
      charityAmountType,
    } = data;
    giftAmount = parseFloat(giftAmount as any) || 0;
    userAmount = parseFloat(userAmount as any) || 0;
    const remainingAmount =
      collectedAmount - ((giftAmount || 0) + (cardAmount || 0));

    if (wantToDonate) {
      if (charityAmountType == 'autoFilled' || decidedGift) {
        charityAmount = remainingAmount; // add new cardAmount
        userAmount = giftAmount;
      } else {
        userAmount = remainingAmount - charityAmount;
        giftAmount = remainingAmount - charityAmount;
      }
    } else {
      charityAmount = 0;
      userAmount = (giftAmount || 0) + remainingAmount;
      if (!giftAmount) giftAmount = userAmount;
    }

    data = {
      ...data,
      charityAmount,
      userAmount,
      giftAmount,
      remainingAmount,
    };
    this.bankAccounts = new Page({
      api: _bankAccountService.bankAccounts,
      properties: new BankAccount(),
      serverPaging: false,
    });

    // this.fetch();
    this.done(data);
  }

  ngOnInit() {}

  ngAfterViewInit() {
    // if (!this._appService.user.address) {
    //   this.customTostorRef = this._appService.snackBarOpenTemplate(this.customTostor, 10000);
    //   this.dialogRef.close();
    // }
  }

  fetch() {
    this.bankAccounts
      .fetch()
      .then((d) => {
        if (!d.items.length) this.addBankAccount();
      })
      .catch((err) => {
        this._appService.snackBarOpen(err);
      });
  }

  addBankAccount() {
    if (!this._appService.user.address) return;
    let bank = this._appService.matDialog.open(AddBankAccountComponent, {
      maxWidth: '100%',
      maxHeight: '100%',
    });
    bank.afterClosed().subscribe((d) => {
      if (d) {
        this.bankAccounts.add(d);
      }
    });
  }

  done(data: Campaign) {
    this.isLoading = true;
    var {
      purchaseCard,
      charityAmount,
      comment,
      cardAmount,
      userAmount,
      cardRecipientEmail,
      campaignCurrency,
      charityId,
      giftAmount,
      automaticCardEmail,
      cardEmailTime,
      receipientEmail,
      selectedTemplateUrl,
      stripeCharges,
      templateType
    } = data;
    // if (this.data.collectedAmount <= 500) {
    //   if (userAmount >= 0 && userAmount <= 4) {
    //     userAmount = 0;
    //   } else {
    //     userAmount = userAmount - 4;
    //   }
    // } else if (this.data.collectedAmount > 500) {
    //   userAmount = userAmount - 6;
    // }
    userAmount = parseFloat(userAmount.toFixed(2));

    let body: any = {
      purchaseCard,
      charityAmount,
      cardAmount,
      comment,
      userAmount,
      cardRecipientEmail,
      campaignCurrency,
      charityId,
      giftAmount,
      automaticCardEmail,
      cardEmailTime,
      receipientEmail,
      stripeCharges,
      selectedTemplateUrl,
      ...this.bankAccounts.selected,
    };
    console.log(body, 'payout body start');
// body.cardEmailTime = new Date(body.cardEmailTime?.getUTCFullYear(),
// body.cardEmailTime?.getUTCMonth(),
// body.cardEmailTime?.getUTCDate(),
// body.cardEmailTime?.getUTCHours(),
// body.cardEmailTime?.getUTCMinutes(),
// body.cardEmailTime?.getUTCSeconds()
// );
    this._campaignService.campaigns
      .update(this.data.id, body, null, `redeem/donate/${this.data.id}`)
      .then((d) => {
        console.log(d, 'payout body d');
        
        this.dialogRef.close(d);
        //The payout amount is on its way to your bank account and will arrive within 7 days
        if(data?.payoutType=='catalogue'){
          this._appService.snackBarOpen(
            'The gift voucher is sent to your email address linked with your CollectaGift account.');
        }else{

          this._appService.snackBarOpen(
            'The virtual VISA card is sent to your email address linked with your CollectaGift account.');
          }
      })
      .catch((err) => {
        this.isLoading = false;
        this.dialogRef.close(false);
        this._appService.snackBarOpen(err);
      });
  }
}

import { Pipe, PipeTransform, ChangeDetectorRef } from '@angular/core';
import { Observable } from 'rxjs';
import { waitForAsync } from '@angular/core/testing';
import { MargeImagesService } from 'src/app/services/margeImages.service';
import { environment } from 'src/environments/environment';
declare const generateImage, urlToBase64, toBase46;

@Pipe({
  name: 'base64',
})
export class Base64Pipe implements PipeTransform {
  constructor(private _margeImagesService: MargeImagesService) {}

  transform(file: any, args?: any): any {
    if (file) {
      if (typeof file === 'string') {
        return this.getImgfromUrl(file);
      } else {
        return this.getImgFromFile(file);
      }
    }
  }

  getImgFromFile(file: File): Observable<string> {
    return Observable.create((observer) => {
      let reader = new FileReader();
      reader.onloadend = (e) => {
        observer.next(reader.result);
        observer.complete();
      };
      reader.readAsDataURL(file);
    });
  }

  getImgfromUrl(url: string) {
    return Observable.create(async (observer) => {
      try {
        const img = await this._margeImagesService.getImage(url);
        console.log(img);
      } catch (error) {
        console.log(error);
        observer.next('');
      }
      observer.complete();
    });
  }
}

import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';
import { FireContribution } from '../models/contribution';

@Injectable({
  providedIn: 'root',
})
export class CardFirebaseService {
  constructor(private firestore: AngularFirestore) {}

  getContributions(campaignId: string) {
    return this.firestore
      .collection('campaigns')
      .doc(`${campaignId}`)
      .collection<FireContribution>('wishes')
      .snapshotChanges()
      .pipe(
        map((data) => {
          return data.map((e) => e.payload.doc.data());
        })
      );
  }

  updateContribution(
    campaignId: string,
    contributionId: any,
    data: FireContribution
  ) {
    this.firestore
      .collection('campaigns')
      .doc(`${campaignId}`)
      .collection<FireContribution>('wishes')
      .doc(`${contributionId}`)
      .update(Object.assign({}, data));
  }

  addContribution(
    campaignId: string,
    contributionId: string,
    data: FireContribution
  ) {
    this.firestore
      .collection('campaigns')
      .doc(`${campaignId}`)
      .collection<FireContribution>('wishes')
      .doc(`${contributionId}`)
      .set(Object.assign({}, data));
  }
}

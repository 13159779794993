import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
// import { CreditCardValidator } from 'ngx-credit-cards';
import { UserService } from 'src/app/services/user.service';
import { AppService } from 'src/app/services/app.service';
import { MatDialogRef } from '@angular/material/dialog';
import { ValidationService } from '../../controls-msg/validation.service';
import { CardService } from 'src/app/services/card.service';
// declare var stripe: any;
// declare var elements: any;

@Component({
  selector: 'app-add-card-detial',
  templateUrl: './add-card-detial.component.html',
  styleUrls: ['./add-card-detial.component.scss']
})
export class AddCardDetialComponent implements OnInit {

  isSubscribe: boolean = false;
  @ViewChild('cardInfo') cardInfo: ElementRef;
  _totalAmount: number;
  card: any;
  cardHandler = this.onChange.bind(this);
  cardError: string;
  isLoading: boolean = false;

  // form: FormGroup = new FormGroup({
  //   cardNumber: new FormControl('', [Validators.required, ValidationService.creditCardNumberValidator]),
  //   cardExpirationDate: new FormControl('', [Validators.required, ValidationService.creditCardExpiryDateValidator]),
  //   cardCvx: new FormControl('', [Validators.required, ValidationService.creditCardCVVNumberValidator]),
  //   cardCurrency: new FormControl('', Validators.required)
  // })

  constructor(
    private _userService: UserService,
    public _appService: AppService,
    public dialogRef: MatDialogRef<AddCardDetialComponent>,
    public cardService: CardService,
    private cd: ChangeDetectorRef,
  ) {
    dialogRef.addPanelClass('add-card-detail')
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    if (this.card) {
      // We remove event listener here to keep memory clean
      this.card.removeEventListener('change', this.cardHandler);
      this.card.destroy();
    }
  }
  ngAfterViewInit() {
    this.initiateCardElement();
  }

  initiateCardElement() {
    const cardStyle = {
      base: {
        color: '#32325d',
        fontFamily: ' "Poppins", sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    };
    // this.card = elements.create('card', { cardStyle });
    // this.card?.mount(this.cardInfo?.nativeElement);
    // this.card?.addEventListener('change', this.cardHandler);
  }
  onChange({ error }) {
    if (error) {
      this.cardError = error.message;
    } else {
      this.cardError = null;
    }
    this.cd.detectChanges();
  }

  async createStripeToken() {
    // this.isLoading = true;
    // const { token, error } = await stripe.createToken(this.card);
    // if (token) {
    //   this.dialogRef.close(token.id);
    // } else {
    //   this.isLoading = false;
    //   this._appService.snackBarOpen(error);
    // }
  }

}

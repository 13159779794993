<form (ngSubmit)="createStripeToken()">
  <header>
    <h4 class="m-0">
      ADD YOUR CARD DETAIL
    </h4>
    <button type="button" class="ml-auto" mat-icon-button (click)="dialogRef.close()">
      <mat-icon>clear</mat-icon>
    </button>
  </header>
  <mat-progress-bar *ngIf="isLoading" [mode]="'indeterminate'"></mat-progress-bar>
  <br>
  <div class="px-3">
      <div id="form-field">
        <div class="p-relative">
          <div id="card-info" [class.invalid]="cardError" #cardInfo class="card-info"></div>
          <mat-error id="card-errors" role="alert" class="errorMsg" *ngIf="cardError">
            <mat-icon inline="true" style="color: #f44336">cancel</mat-icon>
            &nbsp;{{ cardError }}
          </mat-error>
        </div>
      </div>
  </div>
  <br>
  <hr class="m-0">
  <footer class="text-right p-3">
    <button type="submit" id="submit-button" mat-button ButtonUi color="c1" [disabled]="isLoading">
      Submit
    </button>
  </footer>
</form>
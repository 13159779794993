import { Injectable } from "@angular/core";
import { IApi } from '../http-handler/common/contracts/api';
import { giftSuggestion } from '../models/gift-suggestion.model';
import { HttpClient } from '@angular/common/http';
import { GenericApi } from '../http-handler/common/generic-api';

@Injectable({
    providedIn:'root'
})

export class GiftSuggestionService{

    giftSuggestion:IApi<giftSuggestion>;

    constructor(http:HttpClient){

        this.giftSuggestion = new GenericApi<giftSuggestion>('giftSuggestions',http);

    }

}
<form [formGroup]="form">
  <header>
    <h4 class="m-0">
      ADD YOUR KYC
    </h4>
    <button type="button" class="ml-auto" mat-icon-button (click)="dialogRef.close()">
      <mat-icon>clear</mat-icon>
    </button>
  </header>

  <mat-progress-bar *ngIf="uploader?.isUploading" [mode]="'indeterminate'"></mat-progress-bar>
  <br>
  <div class="px-3 content">
    <h5>
      In order to faciliate the CollectaGift funds to be paid out to your bank account,
      Stripe - our payment provider, require you to complete the following information.
      This is a one-time requirement and once provided once, it will not be required again.
      This is required for KYC purposes and to eliminate fraud.
      All provided information will be safely and securely processed by Stripe, our payment provider.
    </h5>
    <div class="row">
      <div class="col-4">
        <country-select [type]="'mat-form-field'" [countryCodeControl]="form.get('country')" [validation]="true"
          [touched]="form.get('country').touched" [selected]="_appService?.user?.country"></country-select>
      </div>

      <div class="col-4">
        <mat-form-field [appearance]="'outline'">
          <mat-label>State</mat-label>
          <input type="text" maxlength="50" matInput placeholder="State" formControlName="state"
            autocomplete="new-password">
        </mat-form-field>
      </div>

      <div class="col-4">
        <mat-form-field [appearance]="'outline'">
          <mat-label>City</mat-label>
          <input type="text" maxlength="50" matInput placeholder="City" formControlName="city"
            autocomplete="new-password">
        </mat-form-field>
      </div>

      <div class="col-4">
        <mat-form-field [appearance]="'outline'">
          <mat-label>Postal Code</mat-label>
          <input type="text" maxlength="10" matInput placeholder="Postal Code" formControlName="postalCode"
            autocomplete="new-password">
        </mat-form-field>
      </div>

      <div class="col-4">
        <mat-form-field [appearance]="'outline'">
          <mat-label>Address</mat-label>
          <input type="text" matInput placeholder="Address" formControlName="address" autocomplete="new-password">
        </mat-form-field>
      </div>

      <div class="col-4">
        <mat-form-field [appearance]="'outline'">
          <mat-label>Phone no.&nbsp;&nbsp;&nbsp;</mat-label>
          <div matPrefix class="phone-code" >
            <span>+44</span>
          </div>
          <!-- <mat-icon matPrefix  >+44</mat-icon> -->
          <input type="text" matInput placeholder="Phone No." formControlName="phone" autocomplete="new-password">
        </mat-form-field>
      </div>

      <div class="col-4">
        <mat-form-field [appearance]="'outline'">
          <mat-label>Date of birth</mat-label>
          <input id="dob" matInput type="search" [max]="minAgeDate" [matDatepicker]="dob" (click)="dob.open()"
            formControlName="dob" autocomplete="new-password" onkeydown="return false">
          <mat-datepicker-toggle matSuffix [for]="dob"></mat-datepicker-toggle>
          <mat-datepicker startView="multi-year" #dob></mat-datepicker>
        </mat-form-field>
      </div>



      <!-- <div class="col-4 orange">
        <label >Proof Type</label> <br>
          <mat-radio-group formControlName="type" >
            <mat-radio-button value="IDENTITY_PROOF" class="mr-3" >IDENTITY PROOF</mat-radio-button>
            <mat-radio-button value="ADDRESS_PROOF" >ADDRESS PROOF</mat-radio-button>
          </mat-radio-group>
      </div> -->

      <div class="col-12">
        <div class="d-flex">
          <div class="mr-3 mb-3 proof-card" [style.z-index]="i == 2 ? '1' : '0'"
            *ngFor="let item of proofsFields;let i = index;">
            <div [ngSwitch]="i">
              <div *ngSwitchCase="0"><label>Upload ID proof</label></div>
              <div *ngSwitchCase="1" class="c-transparent"><label>U</label></div>

              <label *ngSwitchCase="0">Front</label>
              <label *ngSwitchCase="1">Back</label>
              <div *ngSwitchCase="2"><label class="info">Upload home address proof <mat-icon
                    tooltip="Please upload PNG and JPEG files only.">info</mat-icon></label>
              </div>
              <div *ngSwitchCase="3" class="c-transparent"><label>U</label></div>
              <label *ngSwitchCase="2">Front</label>
              <label *ngSwitchCase="3">Back</label>
            </div>
            <div class="add-image-placeholder" *ngIf="!item?.value" (click)="file.click()">
              <mat-icon>add</mat-icon>
              <input type="file" #file (click)="file.value = null" hidden
                (change)="$event?.target?.files?.length ? previewProof($event,item) : null">
            </div>
            <div class="proof-outer" *ngIf="item?.value">
              <div class="progress">
                <mat-icon [class.d-inline-block]="true" (click)="item.setValue('')">clear</mat-icon>
                <!-- <mat-progress-spinner [class.d-none]="!uploader.isUploading" [strokeWidth]="1.5" [diameter]="20" [value]="uploader.progress" ></mat-progress-spinner> -->
              </div>
              <img class="proof" [src]="item?.value | base64 | async" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <br>
  <hr class="m-0">
  <footer class="text-right p-3">
    <button type="submit" [disabled]="uploader?.isUploading || form?.invalid" mat-button ButtonUi color="c1"
      (click)="onSubmit()">
      Upload
    </button>
  </footer>
</form>
import { Injectable } from '@angular/core';
import { IApi } from '../http-handler/common/contracts/api';
import { User } from '../models/user.model';
import { HttpClient } from '@angular/common/http';
import { GenericApi } from '../http-handler/common/generic-api';
import firebase from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { KycProof } from '../models/kyc-proof';
import { AppService } from './app.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  users: IApi<User>;

  constructor(
    private http: HttpClient,
    public afAuth: AngularFireAuth,
    private _router: Router,
    private _appService: AppService
  ) {
    this.users = new GenericApi<User>('users', http);

    afAuth.authState.subscribe((d) => {
      // console.log(auth);
    });
  }

  async createGuestUser({email, name}) {
    try {
      return await this.users.create({ email, name }, 'guest/user');
    } catch (error) {
      this._appService.snackBarOpen(error);
      throw error;
    }
  }

  getKycProofs() {
    return this.users.simpePost<KycProof>(null, 'add/kyc');
  }

  getIpAddress() {
    return this.http.get<{ ip: string }>('https://api.ipify.org/?format=json');
  }

  deactivateAccount() {
    return this.users
      .remove(this._appService.user.id)
      .then((d) => {
        this.logout();
        return true;
      })
      .catch((err) => {
        this._appService.snackBarOpen(err);
        return false;
      });
  }

  async signInWithGoogle() {
    await this.afAuth.signOut();
    return this.afAuth.signInWithPopup(new firebase.auth.GoogleAuthProvider());
  }

  signInWithFB() {
    return this.afAuth.signInWithPopup(
      new firebase.auth.FacebookAuthProvider()
    );
  }

  async logout() {
    this.afAuth.signOut();
    localStorage.clear();
    this._router.navigate(['/home']);
  }

  setUserOnLocal(u: User) {
    window.localStorage.setItem('user', JSON.stringify(u));
    localStorage.setItem('token', u.token);
  }
}

import { Pipe, PipeTransform } from '@angular/core';
const time = [/([\d]+:[\d]{2})(:[\d]{2})(.*)/, "$1$3"]
var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
var months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

@Pipe({
    name: 'dateTime',
})

export class DateTimePipe implements PipeTransform {

    constructor() {
    }

    transform(value: any, args?: any): any {
        if (!value) return;
        let currentDate: Date = new Date();
        let date: Date = new Date(value);
        if (this.now(date)) {
            return `Now`;
        }

        if (this.today(date)) {
            return `Today at ${date.toLocaleTimeString().replace(time[0], time[1] as any)}`;
        }

        if (this.yesterday(date)) {
            return `Yesterday at ${date.toLocaleTimeString().replace(time[0], time[1] as any)}`;
        }

        if (this.currentWeek(date)) {
            return `${days[date.getDay()].slice(0, 3)} at ${date.toLocaleTimeString().replace(time[0], time[1] as any)}`
        }
        // console.log(date.getMonth(),)

        if (this.currentMonth(date)) {
            // console.log('i am month');
            let d = date.getDate();
            let s = d == 1 ? 'st' : d == 2 ? 'nd' : d == 3 ? 'rd' : 'th'
            return `${d + s} ${days[date.getDay()].slice(0, 3)} at ${date.toLocaleTimeString().replace(time[0], time[1] as any)}`;
        }

        if (date.getFullYear() === currentDate.getFullYear()) {
            // console.log('i am year');
            let d = date.getDate();
            let s = d == 1 ? 'st' : d == 2 ? 'nd' : d == 3 ? 'rd' : 'th'
            return `${d + s} ${months[date.getMonth()].slice(0, 3)} at ${date.toLocaleTimeString().replace(time[0], time[1] as any)}`;
        }
        let d = date.getDate();
        let s = d == 1 ? 'st' : d == 2 ? 'nd' : d == 3 ? 'rd' : 'th'
        return `${d + s}, ${months[date.getMonth()].slice(0, 3)}, ${date.getFullYear()}`





    }

    now(date: Date) {
        let newDate = new Date();
        return (date.getMonth() === newDate.getMonth() && date.getFullYear() === newDate.getFullYear() && date.getDate() === newDate.getDate() && date.getHours() === newDate.getHours() && 4 > newDate.getMinutes() - date.getMinutes())
    }

    today(date: Date) {
        let newDate = new Date();
        return (date.getDate() === newDate.getDate() && date.getMonth() === newDate.getMonth() && date.getFullYear() === newDate.getFullYear())
    }

    yesterday(date: Date) {
        let newDate = new Date();
        return (date.getMonth() === newDate.getMonth() && date.getFullYear() === newDate.getFullYear() && newDate.getDate() - date.getDate() === 1)
    }

    currentWeek(date: Date) {
        let newDate = new Date();
        return (date.getFullYear() === newDate.getFullYear() && date.getMonth() === newDate.getMonth() && 7 >= (newDate.getDate() - date.getDate()))
    }

    currentMonth(date: Date) {
        let newDate = new Date();
        return (date.getFullYear() === newDate.getFullYear() && date.getMonth() === newDate.getMonth())
    }





}

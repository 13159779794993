import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { PersonalInformationComponent } from "./personal-information/personal-information.component";
import { CampaignsComponent } from "./campaigns/campaigns.component";
import { ContributionsComponent } from "./contributions/contributions.component";
// import { PayOutComponent } from "./pay-out/pay-out.component";

const routes: Routes = [
  { path: "", redirectTo: "campaigns", pathMatch: "full" },
  { path: "personal-information", component: PersonalInformationComponent },
  { path: "campaigns", component: CampaignsComponent },
  { path: "contributions", component: ContributionsComponent },
  // {path: 'pay-out' , component:PayOutComponent },
  { path: "**", redirectTo: "personal-information", pathMatch: "full" },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DetailRoutingModule {}

import { Injectable } from '@angular/core';
import { IApi } from '../http-handler/common/contracts/api';
import { Campaign } from '../models/campaign.model';
import { HttpClient } from '@angular/common/http';
import { GenericApi } from '../http-handler/common/generic-api';
import { Order, TremendousProduct } from '../models/tremendous.product.model';
import { AppService } from './app.service';

@Injectable({
  providedIn: 'root',
})
export class CampaignService {
  campaigns: IApi<Campaign>;

  constructor(http: HttpClient, private _appService: AppService) {
    this.campaigns = new GenericApi<Campaign>('campaigns', http);
  }

  getByInviteLink(inviteLink: string): Promise<Campaign> {
    return this.campaigns.simpleGet({
      path: `invite/link?inviteCode=${inviteLink}`,
    });
  }

  getTremendousProduct(): Promise<TremendousProduct[]> {
    const countryCode = this._appService?.user?.country;
    const url = countryCode
      ? `tremendous/product?countryCode=${countryCode}`
      : 'tremendous/product';
    return this.campaigns
      .simpleGet({ path: url })
      .then((d: { products: TremendousProduct[] }) => {
        return d.products;
      })
      .catch((err) => {
        this._appService.snackBarOpen(err);
        return [];
      });
  }

  createOrder(
    { id: campaignId, remainingAmount: amount }: Campaign,
    products: string[]
  ): Promise<Order> {
    return this.campaigns
      .simpePost(
        {
          campaignId,
          amount: amount,
          email: this._appService.user?.email,
          products,
        },
        'tremendous/order'
      )
      .then((d: { order: Order }) => {
        return d.order;
      })
      .catch((err) => {
        this._appService.snackBarOpen(err);
        return null;
      });
  }

  RedeemDonate(model: Campaign, bankDetail?: any): Promise<Campaign> {
    var {
      purchaseCard,
      id,
      payoutType,
      charityAmount,
      comment,
      cardAmount,
      userAmount,
      cardRecipientEmail,
      campaignCurrency,
      charityId,
      giftAmount,
      automaticCardEmail,
      cardEmailTime,
      receipientEmail,
      selectedTemplateUrl,
      stripeCharges,
      templateType,
      isAmountProcessed,
    } = model;
    userAmount =
      payoutType === 'account' ? parseFloat(userAmount.toFixed(2)) : 0;
    let body: any = {
      purchaseCard,
      charityAmount,
      cardAmount,
      comment,
      userAmount,
      cardRecipientEmail,
      campaignCurrency,
      charityId,
      giftAmount,
      automaticCardEmail,
      cardEmailTime,
      receipientEmail,
      stripeCharges,
      templateType,
      selectedTemplateUrl,
      isAmountProcessed,
      ...{ ...bankDetail },
    };
    return this.campaigns
      .update(id, body, null, `redeem/donate/${id}`)
      .then((d) => {
        if (payoutType === 'account') {
          this._appService.snackBarOpen(
            'The payout amount is on its way to your bank account and will arrive within 7 days'
          );
        } else if (payoutType === 'catalogue') {
          this._appService.snackBarOpen(
            'Your catalogue is successfully sent on your email address.'
          );
        }
        return d;
      })
      .catch((err) => {
        this._appService.snackBarOpen(err);
        return null;
      });
  }

  getCampaignId(campaign:Campaign){
    if(!campaign.inviteLink) return '';
    // console.log(link);
    return campaign.inviteLink.split('/').pop();
  }

}

import { Component, OnInit, Inject } from '@angular/core';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'app-image-cropper',
  templateUrl: './image-cropper.component.html',
  styleUrls: ['./image-cropper.component.scss']
})
export class ImageCropperComponent implements OnInit {

  private _croppedFile: ImageCroppedEvent;
   _file:File;
  selectedFile: File;
  isloading: boolean = true;

  constructor(
    public dialogRef: MatDialogRef<ImageCropperComponent>,
    private _appService: AppService,
    @Inject(MAT_DIALOG_DATA) public data: CropOptions
  ) {

    let file = data.event.target.files[0] as File;
    this._file = data.event.target.files[0] as File;

    if(file && !file.type.includes('image')){
      this._appService.snackBarOpen('Only Image file supported. please select only image.');
      dialogRef.close();
       return;
    }

    this.selectedFile = data.event;

  }

  ngOnInit() {
  }

  imageCropped(event: ImageCroppedEvent) {
    this._croppedFile = event;
  }
  imageLoaded() {
    this.isloading = false;
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    this._appService.snackBarOpen('Something wrong with this image. please try again');
  }

  done() {
    var bolbBin = atob(this._croppedFile.base64.split(',')[1]);
    var ary = [];
    for (let i = 0; i < bolbBin.length; i++) {
      ary.push(bolbBin.charCodeAt(i));
    }
    let bb = new Blob([new Uint8Array(ary)], { type: "image/png" });
    let file = new File([bb],this._file.name || 'Select Image',{ type:'image/png' });
    this.dialogRef.close(file);
  }

  select(){
    this.dialogRef.close(this._file);
  }

}

class CropOptions {
  event: any;
  ratio?: number;
  notCrop?:boolean;
}

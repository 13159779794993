import { Component, OnInit } from '@angular/core';
import { StartWithContributeComponent } from '../shareModule/dialogs/start-with-contribute/start-with-contribute.component';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss']
})
export class DetailComponent implements OnInit {

  actionIsOpened:boolean;

  constructor(
    private _appService:AppService
  ) { }

  ngOnInit() {
  }

  contribute(){
    this._appService.matDialog.open(StartWithContributeComponent, {
      disableClose: true,
      minHeight: '200px',
      maxHeight: '100%',
      minWidth: '600px',
      maxWidth: '100%',
    });
    this.actionIsOpened = false;
  }


}

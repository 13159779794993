<div class="dialog-container p-0">
  <header class="border-bottom p-3 d-flex align-items-center">
    <h5 class="m-0">Browse available Gift Providers</h5>
    <div class="ml-auto align-center">
      <input class="form-control" type="search" [(ngModel)]="searchVal" (input)="search()">
      <button mat-icon-button (click)="dialogRef.close()">
        <mat-icon>clear</mat-icon>
      </button>
    </div>
  </header>

  <div class="content">
    <div class="row w-100">
      <div class="col-md-3" *ngFor="let item of tremendousProducts?.list">
        <mat-card class="mb-3">
          <img mat-card-image *ngIf="item?.images?.length" [src]="item?.images[0]?.src" />
          <mat-card-content>
            <p class="text-center h5">
              {{ item?.name | titlecase }}
            </p>
          </mat-card-content>
        </mat-card>
      </div>
      <div class="align-center w-100 justify-center" *ngIf="tremendousProducts?.isLoading">
        <!-- Print Card -->
        <mat-spinner class="d-inline-block ml-2" mode="indeterminate" [strokeWidth]="2" [diameter]="22"></mat-spinner>
      </div>
      <div class="w-100 text-center" *ngIf="
      !tremendousProducts?.list?.length && !tremendousProducts?.isLoading
    ">
        No data is available
      </div>
    </div>




  </div>
</div>
<!-- <div class="container-fluid pl-0 nav-section">
  <img src="assets/images/stripe-header-img.png" alt="" class="img-fluid banner-shape-img">
  <img src="assets/images/stripe-img-heade2.svg" alt="" class="img-fluid banner-gift-img">

  <div class="header-heading ml-auto text-black">
    <img height="150px" src="assets/logos/CollectaGift-Final.png" alt="">
    <p>Group Gifting Reimagined</p>
    <div class="stripe-para">CollectaGift wants to connect with you on stripe for the charity purpose on their website. Please link your
      stripe account with collectaGift to payout the donated amount to your charity. Click on the link below to link
      your stripe account</div>
      <button class="stripe-btn"><span>S</span>Connect with Stripe</button>
  </div>
</div> -->

<div class="container-fluid stripe-sec p-0">
  <div class="row">
    <div class="col-lg-7">
      <div class="p-relative">
        <img class="stripeImg1" src="assets/images/stripe-header-img.png" />
        <img class="stripeImg2" src="assets/images/stripe-img-heade2.svg" />
      </div>
    </div>
    <div class="col-lg-5 vh-100 d-flex align-items-center p-relative">
      <div class="header-heading ml-auto text-black">
        <img height="150px" src="assets/logos/CollectaGift-Final.png" alt="" />
        <p class="my-2 font-16 l-black">Group Gifting Reimagined</p>
        <div class="font-16 black mb-4">
          CollectaGift wants to connect with you on stripe for the charity
          purpose on their website. Please link your stripe account with
          collectaGift to payout the donated amount to your charity. Click on
          the link below to link your stripe account
        </div>
        <a [href]="link" target="_blank" class="stripe-btn"
          ><span><img src="assets/images/stripe.png" /></span>Connect with
          Stripe</a
        >
      </div>
      <img class="grphcsImg" src="assets/images/about_grphcs1.png" />
    </div>
  </div>
</div>

<div class="container privacy-policy pt-5">
  <div class="text-center">
    <h1>Privacy Policy</h1>
  </div>
  <section class="mt-0 pt-4">
    <p>
      <b>We take your privacy very seriously. Please read this privacy policy carefully as it contains important
        information on who we are and how and why we collect, store, use and share your personal information. It also
        explains your rights in relation to your personal information and how to contact us or supervisory authorities
        in the event you have a complaint.</b>
    </p>
    <p>
      We collect, use and are responsible for certain personal information about you. When we do so we are subject to
      the General Data Protection Regulation, which applies across the European Union (including in the United Kingdom)
      and we are responsible as ‘controller’ of that personal information for the purposes of those laws.
    </p>
    <p>
      <b class="italic">We, us, our :</b> CollectaGift Limited <br>
      <b class="italic">Personal information :</b> Any information relating to an identified or identifiable individual
      <br>
      <b class="italic">Special category personal information : </b> Personal information revealing racial or ethnic
      origin, political opinions, religious beliefs, philosophical beliefs or trade union membership, Genetic and
      biometric data Data concerning health, sex life or sexual orientation
    </p>

    <p class="pt-4">
      <b>Personal information we collect</b> <br>
      We may collect and use the following personal information: <br>
      • your name and contact information, including email address <br>
      • location data, if you choose to give this to us <br>
      • your billing information, transaction and payment card information <br>
      • basic payment information to enable us to process your gift/contribution <br>
      • your personal bank account information <br>
      • your personal or professional interests <br>
      • information from accounts you link to us, eg Facebook <br>
      • Information about how you use our website, IT, communication and other systems <br>
      • your responses to surveys, competitions, promotions and polls <br>
      • Information about others provided by you for the purpose of creating a CollectAGift Pot. In such circumstances,
      you must ensure that they have agreed to you providing us with their information. <br>
      This personal information is required to provide services to you. If you do not provide personal information we
      ask for, it may delay or prevent us from providing services to you.
      <br> This website is not intended for use by children and we do not knowingly collect or use personal information
      relating to children.
    </p>

    <p class="pt-4">
      <b>How your personal information is collected</b><br>
      We collect most of this personal information directly from you—in person, by telephone, text or email and/or via
      our website. However, we may also collect information:<br>
      • from publicly accessible sources, eg Companies House or HM Land Registry;<br>
      • from a third party with your consent, eg your bank or building society, Google, Facebook, Stripe or other party
      to which you have already provided the information <br>
      • from cookies on our website—for more information on our use of cookies, please see our cookie policy<br>
      • via our IT systems, eg: – automated monitoring of our websites and other technical systems, such as our computer
      networks and connections, communications systems, email and instant messaging systems;
    </p>

    <p class="pt-4">
      <b>How and why we use your personal information</b> <br>
      Under data protection law, we can only use your personal information if we have a proper reason for doing so, eg:
      <br>
      • to comply with our legal and regulatory obligations; <br>
      • for the performance of our contract with you or to take steps at your request before entering into a contract;
      <br>
      • for our legitimate interests or those of a third party; or <br>
      • where you have given consent. <br>
      A legitimate interest is when we have a business or commercial reason to use your information, so long as this is
      not overridden by your own rights and interests. <br>
      The table below explains what we use (process) your personal information for and our reasons for doing so: <br>
    </p>

    <p class="pt-4">
      <b>Promotional communications</b> <br>
      We may use your personal information to send you updates (by email, text message, telephone or post) about our
      services, including exclusive offers, promotions or new services. <br>
      We have a legitimate interest in processing your personal information for promotional purposes (see above ‘How and
      why we use your personal information’). This means we do not usually need your consent to send you promotional
      communications. However, where consent is needed, we will ask for this consent separately and clearly. <br>
      We will always treat your personal information with the utmost respect and never sell it to other organisations
      for marketing purposes. You have the right to opt out of receiving promotional communications at any time by
      contacting us at hello@collectagift.com. <br>
      We may ask you to confirm or update your marketing preferences if you instruct us to provide further services in
      the future, or if there are changes in the law, regulation, or the structure of our business.
    </p>
    <p class="pt-4">
      <b>Who we share your personal information with</b> <br>
      We routinely share personal information with:<br>
      • third parties we use to help deliver our services to you, eg payment service providers;<br>
      • other third parties we use to help us run our business, eg marketing agencies or website hosts;<br>
      • third parties approved by you, eg social media sites you choose to link your account to or third party payment
      providers.<br>
      We only allow our service providers to handle your personal information if we are satisfied they take appropriate
      measures to protect your personal information . We also impose contractual obligations on service providers
      relating to ensure they can only use your personal information to provide services to us and to you. We may also
      share personal information with external auditors.<br>
      We may disclose and exchange information with law enforcement agencies and regulatory bodies to comply with our
      legal and regulatory obligations. We may also need to share some personal information with other parties, such as
      potential buyers of some or all of our business or during a re-structuring. Usually, information will be
      anonymised but this may not always be possible. The recipient of the information will be bound by confidentiality
      obligations.
    </p>
    <p class="pt-4">
      <b>Where your personal information is held</b> <br>
      Information may be held at our offices and those of our third party agencies, service providers, representatives
      and agents as described above (see above: ‘Who we share your personal information with’).<br>
      Some of these third parties may be based outside the European Economic Area. For more information, including on
      how we safeguard your personal information when this occurs, see below: ‘Transferring your personal information
      out of the UK and EEA’.
    </p>

    <p class="pt-4">
      <b>How long your personal information will be kept</b><br>
      We will keep your personal information while you have an account with us or we are providing services to you.
      Thereafter, we will keep your personal information for as long as is necessary:<br>
      • to respond to any questions, complaints or claims made by you or on your behalf;<br>
      • to show that we treated you fairly;<br>
      • to keep records required by law.<br>
      We will not retain your personal information for longer than necessary for the purposes set out in this policy.
      Different retention periods apply for different types of personal information.
    </p>

    <p class="pt-4">
      <b>Transferring your personal information out of the UK and EEA</b><br>
      To deliver services to you, it is sometimes necessary for us to share your personal information outside the UK
      and/or European Economic Area (EEA), eg:<br>
      • with your and our service providers located outside the UK/EEA;<br>
      • if you are based outside the UK/EEA;<br>
      • where there is an international dimension to the services we are providing to you.<br>
      These transfers are subject to special rules under European and UK data protection law. This means we can only
      transfer your personal information to a country or international organisation outside the UK/EEA where:<br>
      • the European Commission has issued an ‘adequacy decision’ in relation to that country or international
      organisation; or<br>
      • there are appropriate safeguards in place, together with enforceable rights and effective legal remedies for
      data subjects; or<br>
      • a specific exception applies under data protection law<br>
      If you would like further information about data transferred outside the UK or EEA, please contact us (see ‘How to
      contact us’ below).
    </p>

    <p class="pt-4">
      For further information on each of those rights, including the circumstances in which they apply, please contact
      us or see the
      <b>Guidance from the UK Information Commissioner’s Office (ICO) on individuals’ rights under the General Data
        Protection Regulation.</b><br>
      If you would like to exercise any of those rights, please:<br>
      • see below: ‘How to contact us’; and<br>
      • let us have enough information to identify you;<br>
      • let us have proof of your identity and address (a copy of your driving licence or passport and a recent utility
      or credit card bill); and<br>
      • let us know what right you want to exercise and the information to which your request relates.
    </p>

    <p class="pt-4">
      <b>Keeping your personal information secure</b><br>
      We have appropriate security measures to prevent personal information from being accidentally lost, or used or
      accessed unlawfully. We limit access to your personal information to those who have a genuine business need to
      access it. Those processing your information will do so only in an authorised manner and are subject to a duty of
      confidentiality.<br>
      We also have procedures in place to deal with any suspected data security breach. We will notify you and any
      applicable regulator of a suspected data security breach where we are legally required to do so.<br>
      If you want detailed information from Get Safe Online on how to protect your information and your computers and
      devices against fraud, identity theft, viruses and many other online problems, please visit www.getsafeonline.org.
      Get Safe Online is supported by HM Government and leading businesses.
    </p>

    <p class="pt-4">
      <b>How to complain</b><br>
      We hope that we can resolve any query or concern you may raise about our use of your information.<br>
      The General Data Protection Regulation also gives you right to lodge a complaint with a supervisory authority, in
      particular in the European Union (or European Economic Area) state where you work, normally live or where any
      alleged infringement of data protection laws occurred. The supervisory authority in the UK is the Information
      Commissioner who may be contacted at https://ico.org.uk/concerns or telephone: 0303 123 1113.
    </p>

    <p>
      <b>Changes to this privacy policy</b><br>
       [This privacy notice was published on [insert date] and last updated on [insert date].]<br>
        We may change this privacy notice from time to time—when we do we will inform you via our website.<br>
    </p>

    <p class="pt-4">
      <b> How to contact us</b><br>
      Please contact us by email if you have any questions about this privacy policy or the information we hold about you.<br>
       Our contact details are shown below:
    </p>
    <p class="pt-4">
      <b>Our contact details</b>
      <span style="padding-left: 30px;">CollectaGift hello@collectagfit.com</span>
    </p>
  </section>
</div>
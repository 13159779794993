import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Contribution } from 'src/app/models/contribution';

@Component({
  selector: 'app-c-wishes',
  templateUrl: './c-wishes.component.html',
  styleUrls: ['./c-wishes.component.scss'],
})
export class CWishesComponent implements OnInit {
  message: any;
  textMessage: any;
  userName:any

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { contributions: Contribution; type: string , item:any }
  ) {
    this.message = this.data.contributions;
   this.textMessage = this.message?.map((res) => res.message);
   this.userName = this.message?.map((u) => u.user.name );

  }

  ngOnInit() {}

  downloadMessageWall() {

   

    let blob = new Blob([this.textMessage], { type: 'application/text' });
    console.log(blob);
    let download = window.URL.createObjectURL(blob);
    let link = document.createElement('a');
     link.href = download
     link.download = 'message.text'
     link.click()
  }
}

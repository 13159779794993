import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
const URL = "/assets/countries.json";
@Component({
  selector: 'country-select',
  templateUrl: './select-country.component.html',
  styleUrls: ['./select-country.component.scss'],
  exportAs: 'country-select'
})
export class SelectCountryComponent implements OnInit, OnChanges {

  @Input('type') type: 'mat-form-field' | 'normal' = 'normal';
  @Input('control') fullDetail: FormControl = new FormControl();
  @Input('flagControl') flag: FormControl = new FormControl();
  @Input('countryCodeControl') countryCode: FormControl = new FormControl();
  @Input('cellCodeControl') cellCode: FormControl = new FormControl();
  @Input('currencyCodeControl') currencyCode: FormControl = new FormControl();
  @Input('selected') selected: Country | string;
  @Input('disabled') disabled: boolean = false
  @Input('placeholder') label: string = "Select Country";
  @Input() touched: boolean = false;
  @Input() validation: boolean = false;
  @Input() searchMethod: (c: Country, v: string) => boolean = (i, v) => {
    return i.name.toLowerCase().includes(v.toLowerCase())
  }
  @Input() optionValueDisplay: (country: Country) => string = (c) => {
    if (!c) return "";
    return c.name || "";
  };
  @Input() inputValueDisplay: (country: Country) => string = (c) => {
    if (!c) return "";
    return c.name || "";
  };
  @Output('selectedChange') selectedChange: EventEmitter<Country> = new EventEmitter();
  private _countries: Array<Country> = [];
  filterCountries: Array<Country> = [];


  constructor(
    private _httpClient: HttpClient
  ) {
    this.fetch();
    this.fullDetail.valueChanges.subscribe(d => {
      if (typeof d === 'string') this.search(d);
      if (typeof d === 'object') this.onSelect(d);
      if (!d || typeof d !== 'object') {
        this.selected = null;
        this.fullDetail.setErrors({ invalid: true });
        this.flag.setErrors({ invalid: true });
        this.countryCode.setErrors({ invalid: true });
        this.cellCode.setErrors({ invalid: true });
        this.currencyCode.setErrors({ invalid: true });
      }
    })
  }

  ngOnInit() {
    if (this.validation) {
      this.fullDetail.setValidators([Validators.required]);
    }
    if (this.disabled) {
      this.fullDetail.disable();
    } else {
      this.fullDetail.enable();
    }
  }

  async ngOnChanges(change: SimpleChanges) {
    if (change) {
      if (change['selected'] && typeof this.selected === 'object') {
        this.fullDetail.setValue(this.selected);
      }
      if (change['disabled'] && this.disabled) {
        this.fullDetail.disable();
      } else if (change['disabled'] && !this.disabled) {
        this.fullDetail.enable();
      }
      if (change['selected'] && typeof this.selected === 'string') {
        this.fetch(true);
      }
      if (change['touched'] && this.touched) {
        this.makeAllAsTouched();
      }
    }
  }

  async fetch(selected?: boolean) {
    await this._httpClient.get(URL).subscribe((d: { items: Array<Country> }) => {
      this._countries = [...d.items.filter(i => i.name?.toLowerCase()?.includes('United Kingdom'.toLowerCase()))];
      this.filterCountries = [...this._countries];
      if (selected) {
        let item = this._countries.find(i => i.alpha2Code.includes(this.selected as any) || i.callingCodes.includes(this.selected as any) || i.name.toLowerCase().includes(this.selected as any));
        if (item) this.fullDetail.setValue(item);
      }
    });
  }

  search(value: string) {
    this.filterCountries = this._countries.filter(i => this.searchMethod(i, value));
  }

  makeAllAsTouched() {
    this.flag.markAsTouched();
    this.cellCode.markAsTouched();
    this.countryCode.markAsTouched();
    this.currencyCode.markAsTouched();
    this.fullDetail.markAsTouched();

  }

  onSelect(item?: Country) {
    if (!item) item = {};
    this.flag.setValue(item.flag ? item.flag : "");
    this.cellCode.setValue((item.callingCodes && item.callingCodes.length) ? item.callingCodes[0] : "");
    this.countryCode.setValue(item.alpha2Code ? item.alpha2Code : "");
    this.currencyCode.setValue((item.currencies && item.currencies.length) ? item.currencies[0].code : "");
    this.selected = item;
    this.selectedChange.emit(item || {});
  }

}

class Country {
  name?: string;
  topLevelDomain?: string[];
  alpha2Code?: string;
  alpha3Code?: string;
  callingCodes?: string[];
  capital?: string;
  altSpellings?: string[];
  region?: string;
  subregion?: string;
  population?: number;
  latlng?: number[];
  demonym?: string;
  area?: number;
  gini?: number;
  timezones?: string[];
  borders?: string[];
  nativeName?: string;
  numericCode?: string;
  currencies?: {
    code?: string;
    name?: string;
    symbol?: string;
  }[];
  languages?: {
    iso639_1?: string;
    iso639_2?: string;
    name?: string;
    nativeName?: string;
  }[];
  translations?: Object;
  flag?: string;
  regionalBlocs?: {
    acronym?: string,
    name?: string,
    otherAcronyms?: [],
    otherNames?: []
  }[];
  cioc?: string;
}

export class Configuration {
  id?: number;
  cardPrice?: number;
  homepageContent?: HomePageContent = new HomePageContent();
  aboutUsContent?: AboutUsContent = new AboutUsContent();
  campaignContent?: CampaignContent = new CampaignContent();
  faqContent?: FaqContent = new FaqContent();
  homeHeaderImage?: string;
  invitePeopleToContribute?: {
    images: [];
  };
}

export class HomePageContent {
  whyCollectaGift?: ContentItem[] = [];
  collectaGiftCharityChampions?: ContentItem[] = [];
  howItWorks?: {
    description?: string;
    heading1?: string;
    heading2?: string;
    heading3?: string;
  };
  ourGiftPartners?: {
    giftPartnerHeading?: string;
    giftPartnerImage1?: string;
    giftPartnerImage2?: string;
    giftPartnerImage3?: string;
    giftPartnerImage4?: string;
  };
  giftPartners?: ContentItem[] = [];
}

export class AboutUsContent {
  welcomeToCollectAGift?: ContentItem[] = [];
}

export class CampaignContent {
  questionsAboutTheGift?: FAQItem[] = [];
  invitePeopleToContribute?: {
    heading1?: string;
    heading2?: string;
    heading3?: string;
    description1?: string;
    bodyText1?: string;
  };
  buyGiftCard?: {
    description1?: string;
  };
  buyGiftCardPaid?: {
    description1?: string;
    question1?: string;
    question1IfYesA?: string;
    question1IfYesB?: string;
    question2?: string;
    question2A?: string;
    question2B?: string;
    question3?: string;
    question2IfChooseA?: string;
  };
}

export class FaqContent {
  startingAnOnlineCollection?: FAQItem[] = [];
  redeemingACollectaGiftPot?: FAQItem[] = [];
  contributingToACollectaGiftPot?: FAQItem[] = [];
  fees?: ContentItem[] = [];
}

export class ContentItem {
  heading?: string;
  description?: string;
}

export class FAQItem {
  que?: string;
  ans?: string;
}

<ng2-slim-loading-bar></ng2-slim-loading-bar>
<router-outlet></router-outlet>
<!-- <router-outlet *ngIf="isPasswordMatch" ></router-outlet> -->

<!-- <ng-template #password >
    <div class="modal-dialog m-0" style="width: 400px;" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Confirmation Dialog</h5>
          </div>
          <div class="modal-body">
            <label for="password">Enter Password</label> <br>
              <div class="input-group mb-3">
                  
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="basic-addon1">
                        <mat-icon style="    height: auto;
                        width: auto;
                        font-size: 15px;
                    " >lock</mat-icon>
                    </span>
                  </div>
                  <input id="password" [(ngModel)]="enteredPassword" type="password" class="form-control" placeholder="Password" aria-label="fdgfhgfhf" >
                </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" (click)="matchPassword()" >Confirm</button>
          </div>
        </div>
      </div>
</ng-template> -->


<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="large" type="square-jelly-box"
    [fullScreen]="true">
    <p style="color: white"> Loading... </p>
</ngx-spinner>
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-connect-stripe",
  templateUrl: "./connect-stripe.component.html",
  styleUrls: ["./connect-stripe.component.scss"],
})
export class ConnectStripeComponent implements OnInit {
  charityId: any;
  link = environment.stripePayoutLink;
  constructor(private _activatedRoute: ActivatedRoute) {
    this._activatedRoute.queryParams.subscribe((params) => {
      this.charityId = params["charityId"];
      if (this.charityId) localStorage.setItem("charityId", this.charityId);
    });
  }

  ngOnInit() {}
}

import { Directive, ElementRef, Input, HostListener, OnInit, OnChanges, ChangeDetectorRef, SimpleChanges } from '@angular/core';
import { NgControl, NgModel } from '@angular/forms';

@Directive({
  selector: '[checkBox]'
})
export class CheckBoxDirective implements OnInit,OnChanges {

  @Input() placeholder: string;
  @Input() color: 'lightGreen' | 'orange' = 'lightGreen';
  @Input() shape: 'rounded' | 'circle' = "circle";
  @Input() class: string;
  input: HTMLInputElement;
  container: HTMLDivElement;
  checkBox: HTMLDivElement;

  checkBoxImg = {
    rounded: {
      lightGreen: ['checkbox-outline', 'checkbox'],
      orange: ['unselected circle', 'orenge tick']
    },
    circle: {
      lightGreen: ['Ellipse 1 copy 9', 'Forma 11 copy 2'],
      orange: ['unselected circle', 'orenge tick']
    }

  }

  constructor(
    private el: ElementRef<HTMLInputElement>,
    private ref: ChangeDetectorRef,
    private ngModel?:NgModel
  ) {
    this.input = el.nativeElement;
  }

  ngOnInit() {
    this.createContainer();
    this.ngModel.valueChanges.subscribe(d => {
      this.onChange(!this.input.checked ? 0 : 1);
    })
  }

  ngOnChanges(changes: SimpleChanges){
    // console.log(changes);
  }



  createContainer() {
    this.container = document.createElement('div');
    if (this.class) this.container.classList.add(this.class);
    this.container.style.display = 'inline-flex';
    this.container.style.alignItems = 'center';
    this.input.after(this.container);
    this.createCheckbox();
  }

  createCheckbox() {
    this.checkBox = document.createElement('div');
    this.checkBox.style.borderRadius = this.shape == 'circle' ? '50%' :  '4px';
    this.checkBox.style.height = '1.3rem';
    this.checkBox.style.display = 'inline-block';
    this.checkBox.style.width = '1.3rem';
    this.checkBox.style.marginRight = '1rem';
    this.checkBox.style.backgroundSize = '100% 100%';
    this.checkBox.style.cursor = 'pointer';
    this.checkBox.style.transition = 'all .15s ease-in-out';
    this.checkBox.addEventListener('mouseover', (event) => {
      this.checkBox.style.backgroundColor = '#00000014';
    });
    this.checkBox.addEventListener('mouseout', (event) => {
      this.checkBox.style.backgroundColor = 'transparent';
    });
    this.checkBox.addEventListener('click', (event) => {
      this.input.click();
    });
    this.onChange(this.input.checked ? 1 : 0);
    this.container.appendChild(this.checkBox);
    this.createPlaceholder();
  }

  createPlaceholder() {
    let placeholder: HTMLSpanElement = document.createElement('span');
    placeholder.style.cursor = 'pointer';
    placeholder.style.textTransform = "capitalize";
    placeholder.innerText = this.placeholder ? this.placeholder : '';
    placeholder.addEventListener('click', (event) => {
      this.input.click();
    });
    this.container.appendChild(placeholder);
    this.input.style.display = "none";
  }

  @HostListener('change', ['$event']) change(event) {
      this.onChange(this.input.checked ? 1 : 0);

  }

  onChange(index: number) {
    this.checkBox.style.backgroundImage = `url('../../../../assets/images/${this.checkBoxImg[this.shape][this.color][index]}.png')`;
  }






}

import { Injectable } from '@angular/core';
import { IApi } from '../http-handler/common/contracts/api';
import { HttpClient } from '@angular/common/http';
import { GenericApi } from '../http-handler/common/generic-api';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationsService {

  configurations: IApi<Configuration>;

  constructor(
    http: HttpClient
  ) {

    this.configurations = new GenericApi<Configuration>('configurations', http);

  }

}

class Configuration {
  cardPrice?: number;
  id?: number;
}

<div class="center h-100">
  <div class="font-18 text-center">
    To create a CollectaGift Pot, you need to be logged in. Please login or
    register here.
  </div>
  <div class="actions mt-3">
    <button ButtonUi color="c1" size="lg" (click)="navigateToLogin()">
      Login / Register
    </button>
  </div>
</div>

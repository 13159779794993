import { AfterViewInit, Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-Privacy-policy',
  templateUrl: './Privacy-policy.component.html',
  styleUrls: ['./Privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements AfterViewInit {

  constructor() { }

  ngAfterViewInit() {
    window.scrollTo(0, 0)
  }

}

<div>
  <h5 class="text-center">Gift Suggestion</h5>
  <ng-container *ngFor="let item of data.contributorGiftIdeas">
    <div class="d-flex justify-content-between">
      <div class="nameText">
        {{ item.url }}
      </div>
    </div>
  </ng-container>
</div>

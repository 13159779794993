import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[cardNum]'
})
export class CardNumDirective {

  constructor(
    private _el: ElementRef<HTMLInputElement>,
    private ngControl: NgControl
  ) { }

  @HostListener('input', ['$event']) input(e) {
    let match: string[] = e.target.value.match(/[0-9]{1,4}/g) || [""];

    this.ngControl.control.setValue(match.join("").substr(0, 16));
    this._el.nativeElement.value = match.join(" ").substr(0, 19);
    if (match.join("").length >= 16) return findNextTabStop(this._el.nativeElement).focus();
  }

}

function findNextTabStop(el): HTMLElement {
  var universe = document.querySelectorAll('input');
  var list = Array.prototype.filter.call(universe, function (item) { return item.tabIndex >= "0" });
  var index = list.indexOf(el);
  return list[index + 1] || list[0];
}

import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-side-nav",
  templateUrl: "./side-nav.component.html",
  styleUrls: ["./side-nav.component.scss"],
})
export class SideNavComponent implements OnInit {
  links: any[] = [
    {
      link: "/pages/detail/campaigns",
      activeImg: "Campaigns dark",
      img: "Campaigns",
      title: "CollectaGift Pots",
    },
    {
      link: "/pages/detail/contributions",
      activeImg: "Contribution Dark",
      img: "Contribute Light",
      title: "My Contributions",
    },
    {
      link: "/pages/detail/personal-information",
      activeImg: "Personal Info Dark",
      img: "Personal Info Light",
      title: "Personal Information",
    },
    // {
    //   link: "/pages/detail/pay-out",
    //   activeImg: "Pay Outs",
    //   img: "Pay Outs",
    //   title: "Pay Out",
    // },
  ];

  constructor() {}

  ngOnInit() {}
}

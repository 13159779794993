<!-- <div class="outer">
  <header class="main-header">
    <h3 class="m-0">
      Payout
    </h3>
    <button mat-icon-button type="button" (click)="dialogRef.close()">
      <mat-icon>clear</mat-icon>
    </button>
  </header>

  <mat-divider></mat-divider>
  <mat-progress-bar *ngIf="bankAccounts?.isLoading || isLoading" [mode]="'indeterminate'"></mat-progress-bar>

  <div class="content">

    <h5 *ngIf="bankAccounts?.items?.length">
      Select the Bank Account on which you want to receive the collection payout
    </h5>

    <h5 *ngIf="!bankAccounts?.items?.length && !bankAccounts?.isLoading">
      You need to add your bank detail first
    </h5>

    <div class="text-center" *ngIf="!bankAccounts?.isLoading && !bankAccounts?.items?.length">
      <img class="mb-3 w-50" src="https://img.icons8.com/bubbles/200/000000/image-file.png" />
      <h6>No bank account available</h6>
      <br>
    </div>

    <mat-card class="bank-card" [class.selected]="bankAccounts?.selected?.id === item?.id"
      (click)="bankAccounts.select(item)" *ngFor="let item of bankAccounts?.items">
      <h5 class="mb-1">
        <strong>{{item?.accountHolderName | titlecase}}</strong>
        <h6 class="ml-3 d-inline mb-0">{{item?.sortCode | titlecase}}</h6>
      </h5>
      <h6 class="mb-1">
        {{item?.accountNumber | squire}}
      </h6>
      <div class="triangle"></div>
      <mat-icon>check</mat-icon>
    </mat-card>

    <button class="w-100" mat-stroked-button color="primary" (click)="addBankAccount()">
      <mat-icon>add</mat-icon>
      Add bank account
    </button>


  </div>

  <mat-divider></mat-divider>
  <footer>
    <button matRipple ButtonUi color="c1" [disabled]="isLoading || !bankAccounts?.selected" (click)="done()">
      Done
    </button>
  </footer>
</div>

<ng-template #customTostor >
    <div>
        <p class="m-0" >
          You need to complete your KYC first to payout the collection amount.  Click <a (click)="customTostorRef?.dismiss()" routerLink="/pages/detail/personal-information" [queryParams]="{kyc:'add'}" >here</a> to complete KYC
        </p>
    </div>
</ng-template> -->

<mat-progress-spinner mode="indeterminate" [diameter]="50"></mat-progress-spinner>
import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { AppService } from 'src/app/services/app.service';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { FileUploader, FileItem } from 'ng2-file-upload';
import { environment } from 'src/environments/environment';
import { CurrenciesService } from 'src/app/services/currencies.service';
import { ImageCropperComponent } from 'src/app/modules/shareModule/components/image-cropper/image-cropper.component';
import { ValidationService } from 'src/app/modules/shareModule/controls-msg/validation.service';
import * as moment from 'moment';

@Component({
  selector: 'app-add-kyc-proofs',
  templateUrl: './add-kyc-proofs.component.html',
  styleUrls: ['./add-kyc-proofs.component.scss']
})
export class AddKycProofsComponent implements OnInit {

  uploader: FileUploader;

  date = new Date()


  form: FormGroup = new FormGroup({
    documentBack: new FormControl('', [Validators.required]),
    documentFront: new FormControl('', [Validators.required]),
    additionalDocumentBack: new FormControl('', [Validators.required]),
    additionalDocumentFront: new FormControl('', [Validators.required]),
    dob: new FormControl('', [Validators.required]),
    address: new FormControl('', [Validators.required, ValidationService.spaceValidator]),
    city: new FormControl('', [Validators.required, ValidationService.spaceValidator]),
    country: new FormControl('', [Validators.required]),
    postalCode: new FormControl('', [Validators.required, ValidationService.spaceValidator, ValidationService.postalCodeValidator(this._appService?.user?.country)]),
    state: new FormControl('', [Validators.required,ValidationService.spaceValidator]),
    ipAddress: new FormControl('192.168.1.10'),
    phone: new FormControl('', [Validators.required, ValidationService.phoneNumberValidator]),
  });
  readonly proofsFields = [this.fControls.documentFront, this.fControls.documentBack, this.fControls.additionalDocumentFront, this.fControls.additionalDocumentBack];

  minAgeDate: any = moment().subtract(16, 'year').toISOString();

  constructor(
    private _userService: UserService,
    public _appService: AppService,
    public dialogRef: MatDialogRef<AddKycProofsComponent>,
    public countriesService: CurrenciesService
  ) {

    this.fControls.country.valueChanges.subscribe(d => {
      this.fControls.postalCode.setValidators([Validators.required, ValidationService.spaceValidator, ValidationService.postalCodeValidator(d)])
    })

    _userService.getIpAddress().subscribe(d => {
      this.form.get('ipAddress').setValue(d.ip);
    });


    dialogRef.addPanelClass('add-kyc-proofs');

    this.uploader = new FileUploader({
      url: `${environment.apiUrls.api}/api/users/add/kyc`,
      removeAfterUpload: false,
      queueLimit: 1,
      autoUpload: true,
      itemAlias: 'documentFront',
      headers: [{ name: 'x-access-token', value: localStorage.getItem('token') }]
    });

    this.uploader.onAfterAddingFile = (item) => {
      item.withCredentials = false;
    }

    this.uploader.onBuildItemForm = (fileItem: FileItem, form: FormData) => {
      let values = this.form.value;
      delete values.documentFront;
      Object.keys(values).forEach(k => form.append(k, values[k]));
    }

    this.uploader.onSuccessItem = (item: FileItem, res: string, status: number, headers) => {
      let responce: { "isSuccess": boolean, "message": string, error: string } = JSON.parse(res);
      if (responce.isSuccess) {
        this._appService.snackBarOpen('KYC proof successfully uploaded');
        this.dialogRef.close(true);
      } else {
        this.uploader.clearQueue();
        _appService.snackBarOpen(responce.error);
      }
    }

    this.uploader.onErrorItem = (item: FileItem, res: string, status: number, headers) => {
      this.uploader.clearQueue();
      // this.uploader.addToQueue([item._file]);
      this._appService.snackBarOpen('File Upload faild please try to upload again');
    }

  }

  get fControls() {
    return this.form.controls;
  }

  ngOnInit() {
  }

  previewProof(event, control: AbstractControl) {

    if (!event?.target?.files[0]) {
      return;
    }

    const file: File = event?.target?.files[0];
    console.log(file);
    if (!file?.type?.includes('png') && !file?.type?.includes('jpeg')) {
      this._appService.snackBarOpen('Please upload PNG and JPEG files only.');
      return;
    }

    let dialog = this._appService.matDialog.open(ImageCropperComponent, {
      data: { event, notCrop: true },
      disableClose: true,
      minWidth: '70vw',
      maxWidth: '100%',
      maxHeight: '100%'
    });
    dialog.afterClosed().subscribe(d => {
      if (d) control.setValue(d);
    })
  }

  onSubmit() {
    this.uploader.addToQueue([this.proofsFields[0].value]);
  }

}

import { Injectable } from '@angular/core';
import { IApi } from '../http-handler/common/contracts/api';
import { HttpClient } from '@angular/common/http';
import { GenericApi } from '../http-handler/common/generic-api';
import { BankAccount } from '../models/bank-account.model';

@Injectable({
  providedIn: 'root'
})
export class BankAccountsService {

  bankAccounts: IApi<BankAccount>;

  constructor(
    http: HttpClient
  ) {

    this.bankAccounts = new GenericApi<BankAccount>('userAccounts', http);

  }

}

<p>
  {{ data.title }}
</p>
<div class="text-center">
  <button
    class="orange"
    ButtonUi
    color="c1"
    size="lg"
    (click)="dialogRef.close()"
  >
    Close
  </button>
</div>

<div class="container terms pt-5">
  <div class="text-center">
    <h1>Terms & Conditions</h1>
  </div>
  <section class="mt-0 pt-4">
    <p>
      <b>1 About our Terms</b><br>
      1.1 These Terms explain how you may use this website (the Site). These Terms also explain what we do for you, what
      you need to do, and how the process of making a gift is handled.<br>
      1.2 References in these Terms to the Site includes the following websites: www.collectagift.com and all associated
      web pages.<br>
      1.3 You should read these Terms carefully before using the Site.<br>
      1.4 By accessing or using the Site or otherwise indicating your consent, you agree to be bound by these Terms and
      the documents referred to in them.<br>
      1.5 If you do not agree with or accept any of these Terms, you should stop using the Site immediately.<br>
      1.6 If you have any questions about the Site, please contact us by e-mail at <a
        style="color: #3C49BF;font-weight: 600;">hello@collectagift.com.</a>
    </p>
    <p class="pt-4">
      <b>1.7 Definitions</b><br>
      <b>Content :</b> means any text, images, video, audio or other multimedia content, software or other information
      or material submitted to or on the Site;<br>
      <b>User Content :</b> means any text, images, video, audio or other multimedia content, software or other
      information or material submitted to the Site by you;<br>
      <b>Site :</b> has the meaning given to it in clause Error! Reference source not found.;<br>
      <b>Terms :</b> means these terms and conditions of use as updated from time to time under clause 15;<br>
      <b>Unwanted Submission :</b> has the meaning given to it in clause 9.1;<br>
      <b>we :</b> means CollectaGift Limited, company registration number 12763692, and the registered office of which
      is at 86 Ashurst Road Barnet EN4 9LG (and us or our shall have the same meaning);<br>
      <b>you :</b> means the person accessing or using the Site or its Content (and your shall have the same
      meaning).<br>
      <b>CollectAGift Pot :</b> means a virtual collection pot created by the Organiser as a means of pooling
      Contributions intended to fund a gift (or gifts) for the Recipient.<br>
      <b>Contributions :</b> means monetary contributions made towards a CollectAGift pot.<br>
      <b>Organiser :</b> means the person using the Site to create a CollectAGift Pot. This must be an individual and
      not a company.<br>
      <b>Contributor :</b> means any person using the Site to make Contributions.<br>
      <b>Recipient :</b> means the person(s) intended to receive the gift (or gifts) funded by a CollectAGift pot.
    </p>

    <p class="pt-4">
      <b>2 Using the Site</b><br>
      2.1 The Site is for your personal and non-commercial use only.<br>
      2.2 You agree that you are solely responsible for:<br>
      2.2.1 all costs and expenses you may incur in relation to your use of the Site; and<br>
      2.2.2 where applicable, keeping your password and other account details confidential.<br>
      2.3 The Site is intended for use primarily by those who can access it from within the UK. If you choose to access
      the Site from locations outside the UK, you are responsible for compliance with local laws where they are
      applicable.<br>
      2.4 We seek to make the Site as accessible as possible. If you have any difficulties using the Site, please
      contact us at hello@collectagift.com.<br>
      2.5 As a condition of your use of the Site, you agree to use the Site only for personal, non-commercial purposes.
      As a condition of your use of the Site, you agree not to use the Site:<br>
      2.5.1 for any purpose that is unlawful under any applicable law or prohibited elsewhere in these Terms;<br>
      2.5.2 to commit any act of fraud; 2.5.3 to distribute viruses or malware or other similar harmful software
      code;<br>
      2.5.4 for purposes of promoting unsolicited advertising or sending spam;<br>
      2.5.5 to simulate communications from us or another service or entity in order to collect identity information,
      authentication credentials, or other information (‘phishing’);<br>
      2.5.6 in any manner that disrupts the operation of our Site or business or the website or business of any other
      entity;<br>
      2.5.7 in any manner that harms minors;<br>
      2.5.8 to promote any unlawful activity;<br>
      2.5.9 to represent or suggest that we endorse any other business, product or service unless we have separately
      agreed to do so in writing;<br>
      2.5.10 to gain unauthorised access to or use of computers, data, systems, accounts or networks; or<br>
      2.5.11 to attempt to circumvent password or user authentication methods.<br>
      2.6 We may prevent or suspend your access to the Site if you do not comply with any part of these Terms, any terms
      or policies to which they refer or any applicable law. Access to certain areas of the Site is restricted. We
      reserve the right to further restrict access to other areas of the Site (or the entire Site) at our discretion.
    </p>

    <p class="pt-4">
      <b>3 Your privacy and personal information</b><br>
      3.1 Your privacy and personal information are important to us. Any personal information that you provide to us
      will be dealt with in line with our privacy policy, which explains what personal information we collect from you,
      how and why we collect, store, use and share such information, your rights in relation to your personal
      information and how to contact us and supervisory authorities in the event you have a query or complaint about the
      use of your personal information.<br>
      3.2 Our privacy policy is available at www.collectagift.com
    </p>

    <p class="pt-4">
      <b>4 Ownership, use and intellectual property rights</b><br>
      4.1 The Site and all intellectual property rights in it including but not limited to any Content are owned by us.
      Intellectual property rights means rights such as: copyright, trademarks, domain names, design rights, database
      rights, patents and all other intellectual property rights of any kind whether or not they are registered or
      unregistered (anywhere in the world). We reserve all of our rights in any intellectual property in connection with
      these Terms. This means, for example, that we remain owners of them and free to use them as we see fit.<br>
      4.2 Nothing in these Terms grants you any legal rights in the Site other than as necessary to enable you to access
      the Site. You agree not to adjust to try to circumvent or delete any notices contained on the Site (including any
      intellectual property notices) and in particular in any digital rights or other security technology embedded or
      contained within the Site.<br>
      4.3 When using the Site you must ensure that any User Content you provide does not breach any intellectual
      property rights of a third party or breach any right or duty owed to a third party. Please ensure that any User
      Content you upload to the Site, especially pictures or photographs, does not infringe another person’s
      copyright.<br>
      4.4 If we find offensive, defamatory, libellous, threatening, harassing, or obscene User Content uploaded onto the
      Site, we will remove it and terminate your access.
    </p>

    <p class="pt-4">
      <b>5 Making a gift</b><br>
      5.1 The Site and its associated services allow you to create and manage a contactless group gifting virtual
      collection pot. Please note that we merely operate the Site which processes Contributions made; you should read
      carefully any descriptions provided and make your own decision regarding the consequences of making a
      Contribution.<br>
      5.2 Below, we set out the process of arranging group gifting by using our Site:<br>
      5.2.1 An Organiser creates a CollectAGift Pot by clicking on the ‘Start an Online Collection’ button and
      completing the presented fields and uploading User Content to present a background to the intention/purpose of
      the gift. The Organiser can either specify an exact gift to be funded, provide an idea of the type of gift to be
      funded, or request gift ideas from Contributors.<br>
      5.2.2 Once the CollectAGift Pot has been created, a link is generated and this is made available to the
      Organiser. The Organiser may then forward that link to Contributors who, upon following the link, will be able
      to make Contributions towards the specific CollectAGift Pot the link relates to.<br>
      5.2.3 Once the deadline for Contributions in relation to a specific CollectAGift Pot has expired, the Organiser
      will be able to transfer the total available funds (see clause 6.2 below) within the CollectaGift Pot to the
      Organiser’s nominated bank account to enable the gift to be purchased.<br>
      5.2.4 If there are any residual funds remaining after a gift has been purchased, there will be the option to
      donate this amount to the Site’s nominated partner charity, Smile Train.
    </p>

    <p class="pt-4">
      <b>6 Payment information</b><br>
      6.1 Your Contributions will be processed via the payment methods available on this Site from time to time. The
      Site operates using the secure online payment platform, Stripe. Please note that standard processing fees will
      apply and will be deducted from each Contribution. The processing fee will differ depending on the
      location/currency of the Contributions. If the Organiser chooses to purchase a digital greeting card, that charge
      will also be deducted from the total available funds. When the Organiser transfers the total available funds to
      their nominated bank account to enable the gift to be purchased, there may also be a Stripe transaction fee
      incurred, which will vary according to the location/currency of the bank account where the funds are transferred
      to.<br>
      6.2 Usually, no Contributions will be refunded for any reason, subject to applicable local laws.<br>\
      6.3 Occasionally, the Organiser may not pass identity verification or other necessary checks, or there may be some
      other reason that we cannot transfer the funds to them. If for any reason the Organiser is unable to successfully
      redeem the funds on expiry of the deadline for Contributions, your Contribution will be refunded via the payment
      method you used to make the Contribution, subject to the deduction of transaction and payment processing fees that
      may be applicable. If for any reason, a Contribution cannot be refunded via the original payment method used to
      make the Contribution, it will be donated to Smile Train, CollectaGift’s nominated partner charity.
    </p>

    <p class="pt-4">
      <b>7 Use of your Contribution</b><br>
      7.1 We offer no guarantee and do not represent or warrant that your Contributions will be used for any specific
      purpose. The Organiser will have the option when creating a CollectAGift Pot to either specify an exact gift to be
      funded, or to provide an idea of the type of gift to be funded before you agree to make a Contribution. However it
      is entirely at the Organiser’s discretion what gift is purchased. We shall not be responsible for any
      dissatisfaction you may have in relation to any gift (or gifts) purchased for the Recipient using your
      Contributions. Once you have made your Contribution, any discussions/disagreements about the use of your
      Contribution will be between you and the Organiser.
    </p>

    <p class="pt-4">
      <b>8 Registering an account</b><br>
      8.1 You may, if you wish to do so, register an account on the Site, which will enable your basic details to be
      retained for future use in connection with additional gifts to be made or contributed towards etc. Notwithstanding
      the above, creation of an account is not mandatory to use the Site.<br>
      8.2 If you do create an account, you will remain solely responsible for maintaining its security through the use
      of a strong password which you must maintain the confidentiality of. If you suspect any unauthorised use of your
      account, you should immediately change your password and contact us (using the details provided above) to discuss
      any ways in which we can assist.
    </p>

    <p class="pt-4">
      <b>9 Submitting information to the Site</b><br>
      9.1 While we try to make sure that the Site is secure, we cannot guarantee the security of any information that
      you supply to us and therefore we cannot guarantee that it will be kept confidential. For that reason, you should
      not let us have any patentable ideas or patent applications, advertising or marketing suggestions, prototypes, or
      any other information that you regard as confidential, commercially sensitive or valuable (Unwanted Submissions).
      While we value your feedback, you agree not to submit any Unwanted Submissions.<br>
      9.2 We may use any Unwanted Submissions as we see reasonably fit on a free-of-charge basis (bear in mind that we
      have no way of knowing whether such information is confidential, commercially sensitive or valuable because we do
      not monitor the Site to check for these matters). Therefore, we will not be legally responsible for keeping any
      Unwanted Submissions confidential nor will we be legally responsible to you or anybody else for any use of such
      Unwanted Submissions.
    </p>

    <p class="pt-4">
      <b>10 Accuracy of information and availability of the Site</b><br>
      10.1 While we try to make sure that the Site is accurate, up-to-date and free from bugs, we cannot promise that it
      will be. Furthermore, we cannot promise that the Site will be fit or suitable for any purpose. Any reliance that
      you may place on the information on the Site is at your own risk.<br>
      10.2 We may suspend or terminate operation of the Site at any time as we see fit.<br>
      10.3 Content is provided for your general information purposes only and to inform you about us and our products
      and news, features, services and other websites that may be of interest. It does not constitute technical,
      financial or legal advice or any other type of advice and should not be relied on for any purposes. You should not
      rely on information given on the Site or its associated services to determine any consequences of making a
      Contribution<br>
      10.4 While we try to make sure that the Site is available for your use, we do not promise that the Site is
      available at all times nor do we promise the uninterrupted use by you of the Site.
    </p>

    <p class="pt-4">
      <b>11 Hyperlinks and third party sites</b><br>
      The Site may contain hyperlinks or references to third party websites other than the Site. Any such hyperlinks or
      references are provided for your convenience only. We have no control over third party websites and accept no
      legal responsibility for any content, material or information contained in them. The display of any hyperlink and
      reference to any third party website does not mean that we endorse that third party’s website, products or
      services. Your use of a third party site may be governed by the terms and conditions of that third party site.
    </p>

    <p class="pt-4">
      <b>12 Limitation on our liability</b><br>
      12.1 Except for any legal responsibility that we cannot exclude in law (such as for
      death or personal injury) or arising under applicable laws relating to the protection of your personal
      information,
      we are not legally responsible for any:<br>
      12.1. I losses that: (a) were not foreseeable to you and us when these Terms
      were formed; or (b) that were not caused by any breach on our part;<br>
      12.1.2 business losses; and<br>
      12.1.3 losses to non-consumers.
    </p>

    <p class="pt-4">
      <b>13 Events beyond our control</b><br>
      We shall have no liability to you for any breach of these Terms caused
      by any event or circumstance beyond our reasonable control including, but not limited to, acts of God,
      governmental
      action, war or national emergency, riot, civil commotion, fire, flood, explosion or accident, inclement weather,
      pandemic, lock outs, strikes or other labour disputes.
    </p>

    <p class="pt-4">
      <b>14 Rights of third parties</b><br>
      No one other than a party to these Terms has any right to enforce any of these Terms.
    </p>

    <p class="pt-4">
      <b>15 Variation</b><br>
      These Terms are dated 6th October 2020. No changes
      to these Terms are valid or have any effect unless agreed by us in writing or made in accordance with this clause
      15. We reserve the right to vary these Terms from time to time. Our updated Terms will be displayed on the Site
      and
      by continuing to use and access the Site following such changes, you agree to be bound by any variation made by
      us.
      It is your responsibility to check these Terms from time to time to verify such variations.
    </p>
    <p class="pt-4">
      
      <b>16 Disputes</b><br>
      16.1 We will try to resolve any disputes with you quickly and efficiently.<br>
      16.2 If you are unhappy with us please contact us as soon as possible.<br>
      16.3 If you and we cannot resolve a dispute using our complaint handling procedure, we will:<br>
      16.3.1 let you know that we cannot settle the dispute with you; and<br>
      16.3.2 give you certain information required by law about alternative dispute resolution.<br>
      16.4 If you want to take court proceedings, the relevant courts of the United Kingdom will have exclusive jurisdiction in relation to these Terms.<br>
      16.5 Relevant United Kingdom law will apply to these Terms.
    </p>
  </section>
</div>
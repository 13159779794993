import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'category'
})
export class CategoryPipe implements PipeTransform {

  transform(v: number, args?: string): any {
    if(args && args.includes('image')){
      return this.image(v);
    }
    if(args && args.includes('class')){
      return this.colorClass(v);
    }
    else{
    return v;
    }
  }

  image(v:number){
    switch(v){
      case 1:
       return 'wedding';
       case 2:
       return 'farewall'; 
       case 3:
       return 'birthday'; 
       case 4:
       return 'corporate'; 
       case 5:
       return 'wedding'; 
       case 6:
       return 'farewall';  
    }
  }

  colorClass(v:number){
    switch(v){
      case 1:
       return 'pink';
       case 2:
       return 'yellow'; 
       case 3:
       return 'blue'; 
       case 4:
       return 'light-purple'; 
       case 5:
       return 'purple'; 
       case 6:
       return 'blue';  
    }
  }

}

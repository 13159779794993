import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { log } from 'console';
import { Campaign } from 'src/app/models/campaign.model';
import { TremendousProduct } from 'src/app/models/tremendous.product.model';
import { AppService } from 'src/app/services/app.service';
import { CampaignService } from 'src/app/services/campaign.service';
import { ContributionService } from 'src/app/services/contributions.service';

@Component({
  selector: 'app-create-catalogue-product-and-proceed',
  templateUrl: './create-catalogue-product-and-proceed.component.html',
  styleUrls: ['./create-catalogue-product-and-proceed.component.scss'],
})
export class CreateCatalogueProductAndProceedComponent implements OnInit {
  tremendousProducts: { list: TremendousProduct[]; isLoading: boolean } = {
    list: [],
    isLoading: false,
  };
  searchVal: any;
  fullTremendousList: TremendousProduct[];
  isLoading: boolean = false;
  selectedProduct: TremendousProduct;

  public get campaign() {
    return this.data.campaign;
  }

  constructor(
    public dialogRef: MatDialogRef<CreateCatalogueProductAndProceedComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { campaign: Campaign; viewOnly: boolean },
    private _appService: AppService,
    private _contributionService: ContributionService,
    private _campaignService: CampaignService
  ) {
    dialogRef.addPanelClass('create-catalogue-product-and-proceed');
    dialogRef.disableClose = true;
    const campaign = this.campaign;
    var collectedAmount=this.campaign.collectedAmount
    var giftAmount = this.campaign.giftAmount
    var  cardAmount =this.campaign.cardAmount
    var remainingAmount=this.campaign.remainingAmount
    var charityAmount = collectedAmount- giftAmount
    charityAmount = (charityAmount-cardAmount||0)

   remainingAmount =
      collectedAmount - (+cardAmount || 0) - (+charityAmount || 0);
    if (this.campaign.wantToDonate) {
      // charityAmount = remainingAmount - this.data.campaign?.giftAmount;
    } else {
      charityAmount = 0;
      this.campaign.giftAmount = remainingAmount;
    }
    data.campaign = {
      ...campaign,
      charityAmount,
      stripeCharges: 0,
      userAmount: 0,
      remainingAmount,
      isAmountProcessed: true,
    };
    this.fetchTremendousProducts();
  }

  ngOnInit() {}

  selectItem(item) {
    if (this.data?.viewOnly) return;
    this.selectedProduct = { ...item };
  }

  async fetchTremendousProducts() {
    this.tremendousProducts.isLoading = true;
    this.tremendousProducts.list =
      await this._campaignService.getTremendousProduct();
    this.fullTremendousList = this.tremendousProducts.list;
    this.tremendousProducts.isLoading = false;
  }

  checkIsInMaxMin(p: TremendousProduct) {
    const min = Math.min(...p.skus.map((i) => parseFloat(i.min)));
    const max = Math.max(...p.skus.map((i) => parseFloat(i.max)));
    return !(
      min <= this.campaign.remainingAmount &&
      this.campaign.remainingAmount <= max
    );
  }

  async create() {
    this.isLoading = true;
    let body = { ...this.campaign };
    if (this.data.campaign?.templateType == 'freeCard') {
      body.cardAmount = 0;
      body.remainingAmount = this.campaign.collectedAmount;
    }
    // this.campaign,
    const order = await this._campaignService.createOrder(body, [
      this.selectedProduct.id,
    ]);
    if (!order) {
      return (this.isLoading = false);
    }
    this.done();
  }

  async done() {
    this.isLoading = true;
    let body = { ...this.campaign };
    if (this.campaign.templateType == 'freeCard') {
      body.cardAmount = 0;
      // body.giftAmount = this.campaign.collectedAmount;
    }

    body 
    const data = await this._campaignService.RedeemDonate(body); //this.campaign
    // console.log(data, 'data done');

    if (!data) {
      return (this.isLoading = false);
    }
    this.isLoading = false;
    this.dialogRef.close(data);
  }

  async search() {
    const searchVal = this.searchVal.toLowerCase();
    this.tremendousProducts.list = this.fullTremendousList;
    if (!this.searchVal) {
      return;
    }
    this.tremendousProducts.list = this.tremendousProducts.list.filter(
      (list) => {
        const name = list.name.toLowerCase();
        return name.includes(searchVal);
      }
    );
  }
}

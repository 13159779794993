import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { CharitiesService } from 'src/app/services/charities.service';
import { ValidationService } from '../../../controls-msg/validation.service';

@Component({
  selector: 'app-invite-charity',
  templateUrl: './invite-charity.component.html',
  styleUrls: ['./invite-charity.component.scss']
})
export class InviteCharityComponent implements OnInit {
  btnDisable:boolean;

   form:FormGroup = new FormGroup ({
    name: new FormControl ('', [Validators.required, Validators.pattern(/[a-zA-Z0-9]/g)]),
    email: new FormControl ('', [Validators.required, ValidationService.emailValidator])
  });

  constructor(
    private charitiesService:CharitiesService,
    public dialog: MatDialogRef<InviteCharityComponent>,
    ) { }

  ngOnInit() {
  }

  onSubmit(){
    if (this.form.invalid) {
      return;
    }
    this.btnDisable = true;
    if (this.form.valid) {
      this.charitiesService.charities.create(this.form.value).then((d) =>{
        this.form.reset();
        this.dialog.close(d);
        this.btnDisable = false;
      }).catch(err => {
        this.btnDisable = false;
      });
    }
  }

}
